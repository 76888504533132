import React from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';

import Sitemap from '../navigation/Sitemap';
import MetaNavigation from '../navigation/MetaNavigation';

function FooterMain(props) {
  const {
    sitemapItems,
    metaItems,
    isContractRenewal,
    withoutSitemap,
  } = props;
  return (
    <footer
      className={suitcss({
        componentName: 'FooterMain',
        modifiers: [isContractRenewal && 'extraPadding'],
      })}
    >
      {!withoutSitemap && (
        <div
          className={suitcss({
            componentName: 'FooterMain',
            descendantName: 'nav',
          })}
        >
          <Sitemap items={sitemapItems} />
        </div>
      )}
      <div
        className={suitcss({
          componentName: 'FooterMain',
          descendantName: 'meta',
        })}
      >
        <MetaNavigation items={metaItems} />
      </div>
    </footer>
  );
}

FooterMain.propTypes = {
  sitemapItems: PropTypes.array.isRequired,
  metaItems: PropTypes.array.isRequired,
  withoutSitemap: PropTypes.bool,
  isContractRenewal: PropTypes.bool,
};

export default FooterMain;
