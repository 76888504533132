import { push } from 'react-router-redux';
import { showNavDialog } from './SuccessPageDialog';

export const redirectToLandingPage = (dispatch, site, isVVL) => {
  const path = (site.contractRenewal.isInProgress || isVVL) ? '/mein-otelo/dashboard' : '/';
  dispatch(push(path));
};
const redirectToSuccessPage = (dispatch, orderId) => {
  const showInfo = '&showInfo=true';
  dispatch(push(`/bestellen/bestaetigung?orderId=${orderId}${showInfo}`));
};

export const _isSuccessPage = (path, sitemap) => ([
    '/bestellen/bestaetigung',
    sitemap.MyContractRenewalSuccessRoute.url,
    sitemap.MyContractRenewalSuccessRoutePre.url,
    sitemap.CheckoutSuccessESimOnlyRoute.url,
    sitemap.CheckoutSuccessPrepaidRoute.url,
  ].includes(path)
);

export const _isVVLPage = (path, sitemap) => ([
    sitemap.MyContractRenewalSuccessRoute.url,
    sitemap.MyContractRenewalSuccessRoutePre.url,
  ].includes(path)
);

/**
 * This function is used to modify the browser navigation
 * and insert a dialog to explain the user where he will be redirected.
 * @param dispatch
 * @param getState
 * @returns {boolean}
 */
export const checkoutNavCatcher = (dispatch, getState) => {
  const {
    cart,
    order,
    site,
    ui,
  } = getState();
  const isSuccessPage = _isSuccessPage(site.routing.currentLocation.pathname, site.sitemap);
  // The traversal order, as of modern ECMAScript specification, is well-defined and consistent
  // across implementations. Within each component of the prototype chain, all non-negative integer
  // keys (those that can be array indices) will be traversed first in ascending order by value,
  // then other string keys in ascending chronological order of property creation.
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/for...in
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/keys
  // -> we can use the last element to get the last order (if multiple are present)
  const orderIds = Object.keys(order);
  const orderId = orderIds.length > 0 ? orderIds.slice(-1)[0] : undefined;

  if (cart.length !== 0 && isSuccessPage) {
    redirectToLandingPage(dispatch, site);
    return true;
  }

  if (cart.length !== 0) {
    return false;
  }

  // we want to go to the previous success page
  const { pathname, search, query } = site.routing?.previousLocation || {};
  if (_isSuccessPage(pathname, site.sitemap)) {
    if (query.showInfo !== 'true') {
      const delimiter = search ? '&' : '?';
      dispatch(push(`${pathname + search + delimiter}showInfo=true`));
    } else {
      dispatch(push(pathname + search));
    }
    return true;
  }

  if (!orderId) {
    redirectToLandingPage(dispatch, site);
    return false;
  }

  if (!isSuccessPage) {
    redirectToSuccessPage(dispatch, orderId);
    return true;
  }

  if (site.routing.currentLocation.query.showInfo) {
    const isVVL = _isVVLPage(site.routing.currentLocation.pathname, site.sitemap);
    showNavDialog(dispatch, site, ui, isVVL);
    return false;
  }

  return false;
};
