import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Swiper, { Navigation, Pagination } from 'swiper';
import oneLine from 'common-tags/lib/oneLine';
import shallowEqual from 'react-pure-render/shallowEqual';

import { compose } from 'redux';
import connectViewport from '../service/ServiceViewportConnector';
import suitcss from '../../../helpers/suitcss';
import SvgLoader from '../media/MediaSvgLoader';
import connectUI from '../ui/UIConnector';

class Slider extends PureComponent {

  static paginationNumberRenderer(swiper, current, total) {
    let htmlString = '';
    for (let i = 1; i <= total; i++) {
      const stateActive = i === current ? ' swiper-pagination-number-active' : '';
      const stateComplete = i < current ? ' swiper-pagination-number-complete' : '';
      htmlString = oneLine`
        ${htmlString}
        <span class="swiper-pagination-number${stateActive}${stateComplete}" data-count="${i}"></span>
      `;
    }
    return htmlString;
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      activeIndex: props.activeIndex || 0,
    };

    this.swiper = null;
    this.sliderContainer = React.createRef();
    this.sliderPagination = React.createRef();
    this.sliderButtonPrev = React.createRef();
    this.sliderButtonNext = React.createRef();

    this.paginationUpdateHandler = this.paginationUpdateHandler.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.changeStartHandler = this.changeStartHandler.bind(this);
    this.changeEndHandler = this.changeEndHandler.bind(this);
    this.slideTo = this.slideTo.bind(this);
  }

  componentDidMount() {
    this.updateSwiper(this.props);
  }

  componentWillReceiveProps(nextProps) {
    const { activeIndex } = this.state;
    if (nextProps.client.width !== this.props.client.width) {
      this.updateSwiper(nextProps);
    }
    if (nextProps.activeIndex && nextProps.activeIndex >= 0
      && nextProps.activeIndex !== activeIndex) {
      this.setState({ activeIndex: nextProps.activeIndex });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.swiper && !shallowEqual(prevProps.config, this.props.config)) {
      this.swiper.destroy();
      this.swiper = null;
    }
    this.updateSwiper(this.props);
  }

  componentWillUnmount() {
    if (this.swiper) {
      this.swiper.destroy();
      this.swiper = null;
    }
  }

  changeHandler() {
    if (!this.swiper) return;
    const { changeHandler } = this.props;
    const { activeIndex } = this.state;

    if (changeHandler) {
      changeHandler(this.swiper.realIndex);
    }
    if (activeIndex != null && activeIndex >= 0) {
      this.setState({
        activeIndex: this.swiper.realIndex,
      });
    }
    if (this.swiper.pagination) {
      this.swiper.pagination.update();
    }
  }

  changeStartHandler() {
    if (!this.swiper) return;
    const { changeStartHandler } = this.props;

    if (this.swiper && this.props.config.loop) {
      /* loop bug workaround https://github.com/nolimits4web/swiper/issues/2629#issuecomment-396802885
        @todo remove once swiper has resolved this issue */
      this.swiper.loopDestroy();
      this.swiper.loopCreate();
    }

    if (changeStartHandler) {
      changeStartHandler(this.swiper.realIndex);
    }
  }

  changeEndHandler() {
    if (!this.swiper) return;
    const { changeEndHandler } = this.props;

    if (changeEndHandler) {
      changeEndHandler(this.swiper.realIndex);
    }
  }

  paginationUpdateHandler() {
    if (!this.sliderPagination.current) return;

    if (this.sliderPagination.current.children.length > 1) {
      this.sliderPagination.current.style.display = 'block';
    } else {
      this.sliderPagination.current.style.display = 'none';
    }
  }

  slideToActive() {
    const { activeIndex } = this.state;
    if (activeIndex != null && activeIndex >= 0 && activeIndex !== this.swiper.realIndex) {
      this.swiper.slideTo(activeIndex);
    }
  }

  slideTo(index) {
    if (index >= 0) {
      this.swiper.slideTo(index);
    }
  }

  updateSwiper(props) {
    if (!this.swiper) {
      this.swiper = this.initSwiper(props);
      setTimeout(() => {
        if (this.swiper) {
          this.swiper.update();
          this.slideToActive();
        }
      }, 10);
    } else {
      this.swiper.update();
    }
  }

  initSwiper(props) {
    const { pagination, ...configProps } = props.config;

    Swiper.use([Navigation, Pagination]);

    const config = {
      loop: false,
      slidesPerView: 1,
      spaceBetween: 0,
      roundLengths: true,
      speed: 500,
      pagination: {
        el: this.sliderPagination.current,
        clickable:
          typeof this.props.pagination?.clickable === 'boolean'
            ? this.props.pagination.clickable
            : true,
        renderBullet: this.props.pagination && typeof this.props.pagination === 'object'
          ? this.props.pagination.renderBullet
          : typeof pagination === 'object'
            ? pagination.renderBullet
            : undefined,
      },
      navigation: {
        nextEl: this.sliderButtonNext.current,
        prevEl: this.sliderButtonPrev.current,
      },
      scrollbar: {
        el: this.sliderScrollbar,
        draggable: true,
        hide: false,
        snapOnRelease: true,
      },
      on: {
        slideChange: this.changeHandler,
        slideChangeTransitionStart: this.changeStartHandler,
        slideChangeTransitionEnd: this.changeEndHandler,
        paginationUpdate: this.paginationUpdateHandler,
      },
      ...configProps,
    };
    return new Swiper(this.sliderContainer.current, config);
  }

  renderArrows() {
    const { arrows, ui } = this.props;
    const NavigationElement = typeof arrows === 'function' && arrows;
    if (NavigationElement) {
      return (
        <NavigationElement
          slideTo={(index) => this.slideTo(index)}
          activeIndex={this.swiper && this.state.activeIndex}
        />
      );
    }
    if (arrows) {
      return (
        <div className={suitcss({ descendantName: 'buttons' }, this)}>
          <button
            className={suitcss({ descendantName: 'buttonPrev' }, this)}
            ref={this.sliderButtonPrev}
            aria-label={ui.guiWordGalleryPrevAlt}
          >
            <SvgLoader path="/icons/content-arrow-dotted.svg" />
          </button>
          <button
            className={suitcss({ descendantName: 'buttonNext' }, this)}
            ref={this.sliderButtonNext}
            aria-label={ui.guiWordGalleryNextAlt}
          >
            <SvgLoader path="/icons/content-arrow-dotted.svg" />
          </button>
        </div>
      );
    }
    return null;
  }

  render() {
    const {
      theme,
      config,
      arrowsAligned,
      className,
    } = this.props;
    const paginationAlignTop = typeof this.props.pagination === 'object' && this.props.pagination.alignTop;

    return (
      <div
        className={suitcss(
          {
            className,
            modifiers: [theme, arrowsAligned && 'aligned', config.centeredContent && 'centered'],
          },
          this,
        )}
      >
        {((this.props.pagination && paginationAlignTop)
          || (config.pagination && config.pagination.alignTop)) && (
          <div
            className={suitcss({ descendantName: 'pagination' }, this)}
            ref={this.sliderPagination}
          />
        )}
        <div className={suitcss({ descendantName: 'slides' }, this)} ref={this.sliderContainer}>
          <div
            className={suitcss({ descendantName: 'wrapper', className: 'swiper-wrapper' }, this)}
          >
            {this.props.children}
          </div>
        </div>
        {((this.props.pagination && !paginationAlignTop)
          || (config.pagination && !config.pagination.alignTop)) && (
          <div
            className={suitcss({ descendantName: 'pagination' }, this)}
            ref={this.sliderPagination}
          />
        )}
        {this.renderArrows()}
      </div>
    );
  }
}

Slider.propTypes = {
  theme: PropTypes.oneOf(['', 'dark', 'light']),
  className: PropTypes.string,
  pagination: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      alignTop: PropTypes.bool,
      renderBullet: PropTypes.func,
      clickable: PropTypes.bool,
    }),
  ]),
  activeIndex: PropTypes.number,
  arrows: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.bool,
  ]),
  arrowsAligned: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
  ]),
  config: PropTypes.shape({
    autoHeight: PropTypes.bool,
    autoResize: PropTypes.bool,
    noTouch: PropTypes.bool,
    centeredSlides: PropTypes.bool,
    spaceBetween: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    loop: PropTypes.bool,
    slidesPerView: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    pagination: PropTypes.object,
  }),
  client: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  changeHandler: PropTypes.func,
  changeStartHandler: PropTypes.func,
  changeEndHandler: PropTypes.func,
  ui: PropTypes.object.isRequired,
};

Slider.defaultProps = {
  arrowsAligned: true,
  config: {},
};

export default compose(
  connectUI(),
  connectViewport({ listenTo: ['client'] }),
)(Slider);
