/**
 * @file
 *
 * User-related requests (those that require authentication) need to be fired
 * sequentially. This reducer manages a queue that ensures requests are send
 * in order.
 */

import oneLine from 'common-tags/lib/oneLine';
import {
  REQUEST_RECEIVED_RESPONSE,
  REQUEST_RECEIVED_ERROR,
} from '../actions/request/basic';
import {
  REQUEST_QUEUE_PARALLEL_REGISTER,
  REQUEST_QUEUE_PARALLEL_PROCESSOR_LOCK,
} from '../actions/request/sendParallel';
import { devInfo } from '../helpers/meta';
import { LOGOUT } from '../actions/user/logout';

const defaultState = () => ({
  requests: [],
  callbacks: {},
  isProcessing: false,
});

/**
 * Inserts a new callback to the callbackMap. The callbackMap maps every request
 * to a list of callbacks.
 *
 * Note: We make sure that a callback map always exists, even if the request
 * did not come with a callback.
 */
const getUpdatedCallbacks = (callbackMap, request, callback = null) => {
  let requestCallbacks = callbackMap[request] || [];

  if (callback) {
    requestCallbacks = [...requestCallbacks, callback];
  }

  return {
    ...callbackMap,
    [request]: requestCallbacks,
  };
};

/**
 * This function will integrate the request into the list of
 * existsting requests. The request is inserted according to its
 * priority.
 *
 * Newly inserted requests are considered to be more important than
 * older requests unless the older request has a higher priority assigned.
 *
 * If a request with the same url already exists in the queue the
 * request will get a new position assigned.
 */
const addToQueue = (state, action) => {
  const { requests, callbacks } = state;
  const { payload } = action;
  const { request, callback } = payload;

  const updatedRequests = [...requests];
  const duplicateIndex = requests.findIndex(request.isEqual);

  if (duplicateIndex !== -1) {
    // remove dublicate from list of request (not from callback list though!)
    updatedRequests.splice(duplicateIndex, 1);
  }

  // insert request based on its priority
  let index = updatedRequests.findIndex(request.compare);
  if (index === -1) { // either the queue is empty or the current request has the lowest prio
    index = updatedRequests.length;
  }

  updatedRequests.splice(index, 0, request);

  // add callback to callback map
  const updatedCallbacks = getUpdatedCallbacks(callbacks, request, callback);

  devInfo(oneLine`
    Request enqueued: ${request} at position ${index + 1} of ${updatedRequests.length}
    (callbacks: ${updatedCallbacks[request].length})
  `);

  return {
    ...state,
    requests: updatedRequests,
    callbacks: updatedCallbacks,
  };
};

/**
 * Removes all requests from the queue that match the type of the
 * request specified in the meta property of the action.
 */
const removeFromQueue = (state, action) => {
  const { requests, callbacks } = state;
  const { meta } = action;
  const { request } = meta;

  if (!request.isParallelized()) {
    // not handled by the queue, hence, ignored
    return state;
  }

  const index = requests.findIndex(request.isEqual);
  if (index === -1) {
    return state;
  }

  const updatedRequests = [...requests];
  updatedRequests.splice(index, 1);

  const updatedCallbacks = { ...callbacks };
  delete updatedCallbacks[request];

  return {
    ...state,
    requests: updatedRequests,
    callbacks: updatedCallbacks,
  };
};

export default (state = defaultState(), action = {}) => {
  switch (action.type) {
    case REQUEST_QUEUE_PARALLEL_REGISTER:
      return addToQueue(state, action);

    case REQUEST_RECEIVED_RESPONSE:
    case REQUEST_RECEIVED_ERROR:
      return removeFromQueue(state, action);

    case REQUEST_QUEUE_PARALLEL_PROCESSOR_LOCK:
      return { ...state, ...action.payload };
    // DBNHAMOP-5425: clear the queue on logout
    case LOGOUT:
      return {
        ...state,
        requests: [],
        callbacks: {},
      };
    default:
      return state;
  }
};
