import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FooterMain from '../../components/compositions/footer/FooterMain';
import { navLinkList } from '../../propTypes/navigation';
import { sendIfNecessary } from '../../actions/request/send';
import LegalsRequest from '../../model/requests/LegalsRequest';

const GlobalFooter = ({ dispatch, footerItems, metaItems, isBranded, isContractRenewal }) => {
  if (!footerItems || !metaItems) {
    return null;
  }

  // get legals/IdMapRequest once on all pages
  // because we don't know where the info is needed
  useEffect(() => {
    dispatch(sendIfNecessary(new LegalsRequest()));
  }, []);

  return (
    <FooterMain
      sitemapItems={footerItems}
      metaItems={metaItems}
      withoutSitemap={isBranded}
      isContractRenewal={isContractRenewal}
    />
  );
};

GlobalFooter.propTypes = {
  footerItems: navLinkList,
  metaItems: navLinkList,
  isBranded: PropTypes.bool,
  isContractRenewal: PropTypes.bool,
};

const makeMapStateToProps = (dispatch) => (state, ownProps) => {
  const { site } = state;
  const footerMenu = state.menu[ownProps.identifierFooter] || {};
  const metaMenu = state.menu[ownProps.identifierMeta] || {};
  const isBranded = !!site.brandName;
  return {
    dispatch,
    footerItems: footerMenu.items,
    metaItems: metaMenu.items,
    isContractRenewal: site.contractRenewal.isInProgress,
    isBranded,
  };
};

export default connect(makeMapStateToProps)(GlobalFooter);
