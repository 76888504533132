import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  registerFootnoteReference,
  unregisterFootnoteReference,
} from '../../actions/legal/footnote';
import FootnoteSymbol from './FootnoteSymbol';
import { getLegalTarget, getVisibleLegals } from '../../helpers/legal';

/**
 * Renders a symbol that references a footnote.
 *
 * If the footnote has no "id" prop specified, it simply renders
 * a default symbol ('*').
 *
 * If a id prop is specified, the Footnote will register itself under
 * this id in the store. Once the component is unmounted,
 * the footnote is unregistered from the store.
 *
 * A id may be e.g. an entity's eid.
 *
 * @example
 *
 * For example the code below...
 *
 *     This is a text somewhere on the website <FootnoteReference id='foobar' />
 *     And this is another text <FootnoteReference id='foobar' />
 *     <br />
 *     <FootnoteSymbol id='foobar' /> This is a footer text
 *
 * ...will render as follows:
 *
 *     This is a text somewhere on the website (1)
 *     And this is another text (1)
 *
 *     (1) This is a footer text
 */
class FootnoteReference extends PureComponent {

  componentDidMount() {
    const { id, dispatch } = this.props;

    if (id != null) { // null or undefined
      dispatch(registerFootnoteReference(id));
    }
  }

  componentWillUnmount() {
    const { id, dispatch } = this.props;

    if (id != null) { // null or undefined
      // put dispatch at end of js event loop to ensure that the unregister
      // does not happen while in unmount state
      setTimeout(() => dispatch(unregisterFootnoteReference(id)), 0);
    }
  }

  render() {
    const { referencedLegals, id } = this.props;

    return (
      <>
        {referencedLegals.map(legal => (
          <FootnoteSymbol
            id={id}
            key={Math.random()}
            refLegal={legal}
          />
        ))}
      </>
    );
  }
}

FootnoteReference.propTypes = {
  /**
   * The id used to identify the footnote reference in the store.
   */
  id: PropTypes.string,
  referencedLegals: PropTypes.array,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const visibleLegals = getVisibleLegals(state);
  const referencedLegals = visibleLegals
    ? visibleLegals.filter(legal => getLegalTarget(legal) === ownProps.id)
    : [];
  return {
    referencedLegals,
    ui: state.ui,
  };
};

const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FootnoteReference);
