import { isBridgeEnabled } from '../../helpers/site';
import ApiError from '../../model/errors/ApiError';
import SessionExpiredError from '../../model/errors/SessionExpiredError';
import ReauthRequest from '../../model/requests/ReauthRequest';
import * as bridge from '../../services/bridge';
import { logout } from '../user/logout';
import {
  requestInProgess,
  receivedResponse,
  receivedError,
  fetch,
} from './basic';
import { sendDirect } from './sendDirect';
import { ACTION_PREFIX } from '../../helpers/constants';

export const REQUEST_QUEUE_PARALLEL_REGISTER = `${ACTION_PREFIX}/REQUEST_QUEUE_PARALLEL_REGISTER`;
export const REQUEST_QUEUE_PARALLEL_PROCESSOR_LOCK = `${ACTION_PREFIX}/REQUEST_QUEUE_PARALLEL_PROCESSOR_LOCK`;

/**
 * Informs the system about whether a processing thread is currently
 * active and processes the queue.
 *
 * Note: Even if no request is in the queue, the processing thread may still
 * be alive.
 *
 * @param {boolean} isProcessing
 */
export const setQueueProcessorLock = (isProcessing) => ({
  type: REQUEST_QUEUE_PARALLEL_PROCESSOR_LOCK,
  payload: { isProcessing },
});

const sendNonceToApp = response => {
  if (response.headers) {
    const nonce = response.headers.get('X-Nonce');
    if (nonce) bridge.setNonce(nonce);
  }
};

/**
 * Action to dispatch in case a queued request failed.
 *
 * First all reducers are informed about the error,
 * then the all response promises are rejected.
 */
const queueError = (request, error) => (dispatch, getState) => {
  const { requestQueueParallel } = getState();
  // note: we grab callbacks before they are removed from store
  const callbacks = requestQueueParallel.callbacks[request];
  dispatch(receivedError(request, error));

  if (!callbacks) {
    // this happens when using redux-hot loading which results in
    // requests sometimes resolving twice. Will never happen on production.
    return;
  }

  callbacks.forEach(cb => cb.reject(error));
};

/**
 * Will remove all previously added request from the queue.
 */
export const unregisterAllRequests = error => (dispatch, getState) => {
  const { requests } = getState().requestQueueParallel;
  requests.forEach(request => dispatch(queueError(request, error)));
};

/**
 * Adds the request and its callback to the list of existing requests
 * in the queue.
 *
 * @param {QueueableRequest} request
 * @param {Deferred} [callback]
 */
export const registerRequest = (request, callback) => ({
  type: REQUEST_QUEUE_PARALLEL_REGISTER,
  meta: {
    identifier: request.id, // used for loading bar
  },
  payload: { request, callback },
});

/**
 * Action to dispatch once a request has been successfuly resolved.
 *
 * First all reducers are informed about the received response,
 * then the all pending promises are resolved.
 *
 * @param {QueueableRequest} request
 * @param {Object} response
 */
const queueSuccess = (request, response) => (dispatch, getState) => {
  const { user, site } = getState();
  // check login state, because we could have received a SessionExpiredError while executing
  // the request and we could run into trouble when we assume
  // a logged in user at this place.
  const isLoggedIn = user.credentials.msisdn;
  if (isLoggedIn) {
    const { requestQueueParallel } = getState();
    // note: we grab callbacks before they are removed from store
    const callbacks = requestQueueParallel.callbacks[request];
    dispatch(receivedResponse(request, response));

    if (!callbacks) {
      // this happens when using redux-hot loading which results in
      // requests sometimes resolving twice. Will never happen on production.
      return;
    }

    callbacks.forEach(cb => cb.resolve(response));
    if (isBridgeEnabled(site)) {
      // in bridge mode pass the nonce back to the app
      sendNonceToApp(response);
    }
  }
};

// Lock Variable: only one request can do the reauth
let reAuthLock = false;
// callbacks for all other failed requests which need to wait until reauth has finished
const reAuthCallbacks = [];
const lock = async (callback) => {
  // if we are the first request, get the lock and try to reauth
  if (!reAuthLock) {
    reAuthLock = true;
    try {
      await callback();
      // after successful finish, resolve all the pending promises
      while (reAuthCallbacks.length > 0) {
        const { resolve } = reAuthCallbacks.pop();
        // do not retry those, only the first one triggers
        resolve(false);
      }
    } catch (e) {
      // on error case, reject the promises
      while (reAuthCallbacks.length > 0) {
        const { reject } = reAuthCallbacks.pop();
        reject(false);
      }
      // for first request, throw the error to be handled
      throw e;
    } finally {
      // remove the lock in each case
      reAuthLock = false;
    }
    // for the main request, trigger retry
    return true;
  } else {
    // lock is already taken. register a promise.
    return new Promise((resolve, reject) => {
      reAuthCallbacks.push({ resolve, reject });
    });
  }
};

/**
 * @param {QueueableRequest} request
 * @param {Error} error
 * @return {Promise<boolean>|undefined} true, if error is recoverable, false if not.
 */
const handleParallelQueueError = (request, error) => async (dispatch, getState) => {
  const { user, site } = getState();

  if (error instanceof ApiError) {
    if (error.fullResponse.status === 401) {
      return lock(async () => {
        const { msisdn } = user.credentials;
        if (msisdn) {
          // do not dispatch the error but try to re-authenticate first
          // note: "await" is necessary here to catch a rejected promise
          await dispatch(sendDirect(new ReauthRequest(msisdn, request)));
          return true;
        } else {
          // the user has never been authenticated; we reject all requests including the current one
          dispatch(unregisterAllRequests(error));
          return false;
        }
      }).then((value) => {
        return value;
      }).catch(() => {
        // The reauthentication failed – this is a critical error that will cause
        // every account-related action to fail. therefore, we logout which will then
        // reject all remaining requests in the queue, including the reauth request.
        // moreover, we expect the "user" reducer to clear the existing credentials.
        dispatch(logout({ error: new SessionExpiredError() }));
        return false;
      });
    } else if (isBridgeEnabled(site)) {
      // in bridge mode pass the nonce back to the app
      sendNonceToApp(error.fullResponse);
    }
  }

  dispatch(queueError(request, error));
  return false;
};

/**
 * Sends requests in queue in parallel.
 * The responses are synchornized on 401 error case to prevent each request to reauthenticate
 * @returns {(function(*, *): Promise<void>)|*}
 */
const processRequests = () => async (dispatch, getState) => {
  const { requestQueueParallel } = getState();

  if (requestQueueParallel.requests.length === 0) {
    return;
  }

  await requestQueueParallel.requests.map(async (request) => {
    try {
      dispatch(requestInProgess(request));
      const response = await dispatch(fetch(request));
      return await dispatch(queueSuccess(request, response));
    } catch (error) {

      const retry = await dispatch(handleParallelQueueError(request, error));
      if (retry) {
        dispatch(processRequests());
      }
    }
  });
  // release lock when all current requests have been sent
  dispatch(setQueueProcessorLock(false));
};

/**
 * TODO: correct comment
 * Puts the request in the existing requests queue.
 *
 * The request will be fired once all remaining higher-prio requests
 * have been successfully processed.
 *
 * Once the request succeeded, a "response received" event is dispatched
 * which can be used in any reducer to store the response. It is
 * also possible to wait for the promise returned by this function,
 * which will contain the response.
 *
 * @param {QueueableRequest} request
 * @return {Promise}
 */
export const sendParallel = request => (dispatch, getState) =>
  new Promise((resolve, reject) => {

    // immediately register request, which will insert it in the request queue
    dispatch(registerRequest(request, { resolve, reject }));
    const { isProcessing } = getState().requestQueueParallel;
    if (isProcessing) {
      // an earlier event already spawned a processing thread so we do not
      // need to start a new processing loop.
    } else {
      // no processing loop is currently active so we will spawn one.
      // note: we deliberately disconnect the callstack here because the
      // request processor might process other requests first and it makes
      // no sense waiting for this.
      dispatch(setQueueProcessorLock(true));
      setImmediate(() => dispatch(processRequests()));
    }
  });
