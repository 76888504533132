import React from 'react';
import suitcss from '../../../helpers/suitcss';

import { navLinkList } from '../../../propTypes/navigation';

import Link from '../../basics/text/TextLink';
import SvgLoader from '../../basics/media/MediaSvgLoader';

function MetaNavigation(props) {
  const { items } = props;
  const logoItem = items.find(item => item.class === 'nav-meta-logo');
  const poweredByItem = items.find(item => item.class === 'nav-meta-poweredby');
  const socialItems = items.filter(item => item.class === 'nav-meta-social');
  const metaItems = items.filter(item => item.class !== 'nav-meta-social'
    && item.class !== 'nav-meta-logo' && item.class !== 'nav-meta-poweredby');
  /**
   * SEO: changed parent from LazyShow to div
   * @see https://jira.db-n.com/browse/OP-2177
   */
  return (
    <div className={suitcss({ componentName: 'MetaNavigation' })}>
      {/* <LazyShowElement
         className={suitcss({ componentName: 'MetaNavigation' })}
         rootMargin="0% 0% -5%"
         isParent
       > */}
      <div
        className={suitcss({
          componentName: 'MetaNavigation',
          descendantName: 'wrapper',
        })}
      >
        {(logoItem || poweredByItem) && (
          <nav>
            <ul
              className={suitcss({
                componentName: 'MetaNavigation',
                descendantName: 'title',
              })}
            >
              {logoItem && (
                <li
                  className={suitcss({
                    componentName: 'MetaNavigation',
                    descendantName: 'link',
                  })}
                  key="meta-logo"
                >
                  <Link
                    linkId="SkipLinkFooterAnchor"
                    to={logoItem.url}
                    withoutArrow
                    icon={logoItem.icon}
                    withoutStyle
                    ariaLabel={logoItem.ariaLabel}
                  />
                </li>
              )}
              {poweredByItem && (
                <li
                  className={suitcss({
                    componentName: 'MetaNavigation',
                    descendantName: 'link',
                  })}
                  key="meta-poweredBy"
                >
                  <Link
                    to={poweredByItem.url}
                    withoutArrow
                    icon={poweredByItem.icon}
                    withoutStyle
                    ariaLabel={poweredByItem.ariaLabel}
                  />
                </li>
              )}
            </ul>
          </nav>
        )}
        <nav
          role="navigation"
          className={suitcss({
            componentName: 'MetaNavigation',
            descendantName: 'meta',
          })}
        >
          <ul>
            {metaItems.map((item, index) => (
              <li
                className={suitcss({
                  componentName: 'MetaNavigation',
                  descendantName: 'link',
                })}
                key={`meta-item-${index}`}
              >
                <Link
                  to={item.url}
                  withoutArrow
                >
                  {item.title}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
        <nav>
          <ul
            className={suitcss({
              componentName: 'MetaNavigation',
              descendantName: 'social',
            })}
          >
            {socialItems.map((item, index) => (
              <li
                className={suitcss({
                  componentName: 'MetaNavigation',
                  descendantName: 'link',
                })}
                key={`meta-social-${index}`}
              >
                <a
                  href={item.url}
                  title={item.title}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={item.ariaLabel}
                >
                  <SvgLoader path={item.icon} />
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
}

MetaNavigation.propTypes = {
  items: navLinkList.isRequired,
};

export default MetaNavigation;
