import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Motion, spring } from 'react-motion';
import suitcss from '../../../helpers/suitcss';
import {
  toIntegerDigits,
  toFractionSeperator,
  toFractionDigits,
  toDecimal,
  toCurrencySymbol,
  toAbsolute,
} from '../../../helpers/money';
import { priceShape } from '../../../propTypes/unit';

const springConfig = { stiffness: 600, damping: 40 };

class TextUnit extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      unit: (props.prefixPrice && props.prefixPrice.unit) || props.price.unit,
    };
  }

  renderPostfix(prefixPrice, ariaHidden = false) {
    const { showCurrency, price, postfix } = this.props;
    const isArray = Array.isArray(postfix);

    return (
      <span className={suitcss({ descendantName: 'postfix', utilities: [prefixPrice && 'colorTertiary'] }, this)}>
        <span aria-hidden={ariaHidden}>{showCurrency && `${toCurrencySymbol(price)} `}</span>
        {this.renderPostfixElements(postfix, prefixPrice, ariaHidden, isArray)}
      </span>
    );
  }

  renderPostfixElements(postfix, prefixPrice, ariaHidden, isArray) {
    if (!isArray) {
      return (
        <span className={suitcss({ utilities: [prefixPrice && 'colorDefault'] })} aria-hidden={ariaHidden}>
          {postfix}
        </span>
      );
    }

    return postfix.map((el, i) => {
      // FootnoteReference should not be hidden from screen readers
      const elAriaHidden = el.type?.displayName?.includes('FootnoteReference')
        ? false
        : ariaHidden;
      return (
        <span key={i} aria-hidden={elAriaHidden}>
          {el}
        </span>
      );
    });
  }

  renderPrefix(ariaHidden = false) {
    const { prefix, prefixPrice, wrap } = this.props;
    return (
      <>
        {prefix && (
          <span className={suitcss({ descendantName: 'prefix' }, this)} aria-hidden={ariaHidden}>
            {prefix}
          </span>
        )}
        {prefixPrice && (
          <del
            className={suitcss(
              { descendantName: 'prefixPrice', utilities: ['strikethrough'] },
              this,
            )}
            aria-hidden
          >
            <span aria-hidden={ariaHidden}>{toDecimal(prefixPrice)}</span>
            {wrap && this.renderPostfix(prefixPrice, ariaHidden)}
          </del>
        )}
      </>
    );
  }

  renderBlock(price, ariaHidden = false) {
    const { priceTheme, prefixPrice } = this.props;
    const utilities = [
      prefixPrice && priceTheme === 'tertiary' && 'colorTertiary',
    ];

    const WrapperTag = prefixPrice ? 'ins' : 'span';

    return (
      <WrapperTag className={suitcss({ utilities }, this)} aria-hidden={prefixPrice ? 'true' : undefined}>
        <span className={suitcss({ descendantName: 'unit' }, this)} aria-hidden={ariaHidden}>
          {toIntegerDigits(price)}
        </span>
        <span className={suitcss({ descendantName: 'seperator' }, this)} aria-hidden={ariaHidden}>
          {toFractionSeperator(price)}
        </span>
        <span className={suitcss({ descendantName: 'raised' }, this)}>
          <span className={suitcss({ descendantName: 'subunit' }, this)} aria-hidden={ariaHidden}>
            {toFractionDigits(toAbsolute(price))}
          </span>
          {this.renderPostfix(null, ariaHidden)}
        </span>
      </WrapperTag>
    );
  }

  renderText(price) {
    const { prefixPrice, prefix, showCurrency } = this.props;

    return `
        ${prefix ?? ''} ${prefixPrice ?? ''}
        ${toIntegerDigits(price)}${toFractionSeperator(price)}${toFractionDigits(toAbsolute(price))}
        ${showCurrency ? toCurrencySymbol(price) : ''}
      `;
  }

  renderInline(price, ariaHidden = false) {
    return (
      <span>
        <span className={suitcss({ descendantName: 'unit' }, this)} aria-hidden={ariaHidden}>
          {toDecimal(price)}
        </span>
        {this.renderPostfix(null, ariaHidden)}
      </span>
    );
  }

  renderContent(price, ariaHidden = false) {
    const {
      prefix, prefixPrice, inline, inverted, bold, wrap,
    } = this.props;
    const size = this.props.size || null;
    const modifiers = [size, wrap && 'wrap'];
    const utilities = [inverted && 'colorInverted', bold && 'weightBold', ...this.props.utilities];

    if (!prefixPrice) {
      return (
        <span
          className={suitcss(
            {
              modifiers,
              utilities,
            },
            this,
          )}
        >
        {(prefix || prefixPrice) && this.renderPrefix(ariaHidden)}
          {inline ? this.renderInline(price, ariaHidden) : this.renderBlock(price, ariaHidden)}
      </span>
      );
    } else {
      return (
        <span
          aria-label={`Alter Preis: ${toDecimal(prefixPrice)} €, aktueller Preis: ${toDecimal(price)} €`}
          className={
            suitcss({
              modifiers,
              utilities,
            }, this)}
        >
        {(prefix || prefixPrice) && this.renderPrefix()}
          {inline && this.renderInline(price)}
          {!inline && this.renderBlock(price)}
      </span>
      );
    }

  }

  render() {
    const { price, withoutTransition, prefixPrice } = this.props;

    if (withoutTransition) {
      return this.renderContent(price);
    }

    const { unit, currency } = price;
    const defaultStyle = { unit: this.state.unit };
    const style = { unit: spring(unit, springConfig) };
    return (
      <>
        {!prefixPrice &&
          <span className="sr-only">{this.renderText(price)}</span>
        }
        <Motion defaultStyle={defaultStyle} style={style}>
          {(value) => this.renderContent({ unit: Math.round(value.unit), currency }, true)}
        </Motion>
      </>
    );
  }
}

TextUnit.propTypes = {
  priceTheme: PropTypes.oneOf([false, null, 'tertiary']),
  prefixPrice: PropTypes.shape({
    unit: PropTypes.number,
  }),
  withoutTransition: PropTypes.bool,
  postfix: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  price: priceShape.isRequired,
  prefix: PropTypes.string,
  size: PropTypes.oneOf(['inherit', 's', 'm', 'l']),
  showCurrency: PropTypes.bool,
  inverted: PropTypes.bool,
  bold: PropTypes.bool,
  utilities: PropTypes.array,
  inline: PropTypes.bool,
  wrap: PropTypes.bool,
};

TextUnit.defaultProps = {
  priceTheme: null,
  showCurrency: true,
  inverted: false,
  bold: true,
  size: 'inherit',
  utilities: [],
};

export default TextUnit;
