/* global document */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable quotes */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';
import SearchResultList from './SearchResultList';
import GlobalSection from '../../basics/global/GlobalSection';
import Lightbox from '../../basics/lightbox/Lightbox';
import TextCopy from '../../basics/text/TextCopy';
import MediaImage from '../../basics/media/MediaImage';
import Link from '../../basics/text/TextLink';
import connectUI from '../../basics/ui/UIConnector';
import TextHeadline from '../../basics/text/TextHeadline';
import { getUrlPath } from '../../../helpers/url';
import SvgLoader from '../../basics/media/MediaSvgLoader';
import RenderOnClient from '../../basics/ssr/RenderOnClient';

const componentName = 'SearchForm';

class SearchForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      trigger: null,
      isVisible: false,
      userInteraction: false,
    };
    this.onOpenLightbox = this.onOpenLightbox.bind(this);
    this.onCloseLightbox = this.onCloseLightbox.bind(this);
    this.onUserInteraction = this.onUserInteraction.bind(this);
    this.renderCmsSearchSuggestions = this.renderCmsSearchSuggestions.bind(this);
    this.renderSearchSuggestion = this.renderSearchSuggestion.bind(this);
    this.onCancelLightbox = this.onCancelLightbox.bind(this);
  }

  // TODO: Initialize search event listener for the navigation
  componentDidMount() {
    const { isGlobalSearch, isCMSConfigured, params: { classForHtmlBind } } = this.props;
    const eventType = isGlobalSearch ? 'click' : 'focus';

    // TODO Setting the query is pretty nasty, try to use variable in sync with styling
    const queryToSearchForm = '.SearchForm .QueryInput input';
    const domSelector = isGlobalSearch
      ? isCMSConfigured ? queryToSearchForm : classForHtmlBind
      : queryToSearchForm;

    if (domSelector) {
      const searchInput = document.querySelector(domSelector);
      if (searchInput) {
        searchInput.addEventListener(eventType, this.onOpenLightbox);
      }
    }
  }

  onOpenLightbox(ev) {
    const { flushSearchQuery } = this.props;
    flushSearchQuery();
    const trigger = ev ? ev.target : null;
    const focusable = trigger?.closest('button');
    this.setState({
      isVisible: true,
      ...(focusable ? { trigger: focusable } : {}),
    });
  }

  onCloseLightbox() {
    // this.props.flushSearchQuery();
    const { trigger } = this.state;
    setTimeout(() => {
      if (trigger) trigger.focus();
    }, 0);
    this.setState({
      isVisible: false,
      trigger: null,
    });
  }

  onCancelLightbox(event) {
    event.preventDefault();
    const { flushSearchQuery } = this.props;
    flushSearchQuery();
    const { trigger } = this.state;
    setTimeout(() => {
      if (trigger) trigger.focus();
    }, 0);
    this.setState({
      isVisible: false,
      trigger: null,
    });
  }

  onUserInteraction() {
    this.setState({ userInteraction: true });
  }

  //------------------------------------------------------------------------------------------------
  //  Helper Methods
  //------------------------------------------------------------------------------------------------

  sortSuggestionsByFacet(facets, data) {
    const sortedData = {};

    for (let i = 0; i < facets.length; i++) {
      sortedData[facets[i]] = data.filter(suggestion => suggestion.facet === facets[i]);
    }
    return sortedData;
  }

  createSuggestionsByFacet(facetName, count, suggestions, index) {
    const {
      isGlobalSearch,
      ui,
      selectSearchTypeForTracking,
      triggerPageTrackingForLinkClick,
      setPreSelectedFacet,
    } = this.props;
    return (
      <RenderOnClient>
        <div
          className={suitcss({ descendantName: 'facet' }, this)}
          key={index}
        >
          {isGlobalSearch && (
            <Link
              withoutArrow
              withoutStyle
              element="button"
              onClick={() => {
                setPreSelectedFacet(facetName);
                selectSearchTypeForTracking(`autosuggest category`);
                this.onCloseLightbox();
              }}
            >
              <span className={suitcss({ descendantName: 'facetName' }, this)}>
                {facetName}
                {'  '}
              </span>
              {ui.guiSearchNResults.replace('{RESULTS}', count)}
            </Link>
            )}
          <ul
            className={suitcss({
              descendantName: 'innerBox',
              modifiers: ['searchSuggestionInnerBox'],
            }, this)}
          >
            {
              suggestions.map((suggestion, id) => (
                <li
                  className={suitcss({ descendantName: 'suggestionFacet' }, this)}
                  /* eslint-disable-next-line react/no-array-index-key */
                  key={id}
                >
                  <Link
                    to={getUrlPath(suggestion.url)}
                    raw
                    withoutArrow
                    withoutStyle
                    onClick={() => {
                      triggerPageTrackingForLinkClick(facetName);
                      // event.preventDefault();
                      // event.stopPropagation();
                      this.onCloseLightbox();
                      // window.location.href = getUrlPath(suggestion.url);
                      // Forces a native reload
                    }}
                  >
                    <div className={suitcss({ descendantName: 'facetSuggestion', utilities: ['flex', 'itemsCenter'] }, this)}>
                      <MediaImage src={suggestion.icon} alt={suggestion.title} />
                      <TextCopy className={suitcss({ descendantName: 'headline', modifiers: [isGlobalSearch && 'globalSearch'] }, this)}>
                        {suggestion.title}
                      </TextCopy>
                    </div>
                  </Link>
                </li>
              ))
            }
          </ul>
        </div>
      </RenderOnClient>
    );
  }

  renderSearchResultsForm() {
    const {
      onSubmit,
      inputValue,
      onInputChange,
      params,
    } = this.props;
    return (
      <div
        className={suitcss({
          componentName: 'queryInputBox',
        }, this)}
      >
        <form
          className={suitcss({
            componentName: 'queryInput',
          }, this)}
          onSubmit={onSubmit}
        >
          <input
            type="text"
            value={inputValue}
            onChange={onInputChange}
            placeholder={params.inputPlaceholder}
            minLength={2}
            required
            aria-label="Service durchsuchen"
          />
          <div
            className={suitcss({
              componentName: 'iconWrapper',
            }, this)}
          >
            <input type="submit" value="" />
            <SvgLoader path="/files/icons/search/icon-suche.svg" />
          </div>
        </form>
      </div>
    );
  }

  /* renderingHitCounter() {
      const { searchMetaInfo, userInteraction, params } = this.props;
      const { resultsTotal, resultsNone } = params;
      return (
        userInteraction ?
          <TextCopy
            className={suitcss({
              componentName,
              descendantName: 'hitcounter',
            })}
          >
            {
              searchMetaInfo.pagination.total > 0 ?
                resultsTotal.replace(/{total}/i, searchMetaInfo.pagination.total) :
                resultsNone
            }
          </TextCopy> : null
      );
    } */

  renderSearchResultItems() {
    const {
      searchResults,
      userInteraction,
      searchMetaInfo,
      params,
      isGlobalSearch,
      preSelectedFacet,
      showNoSearchResults,
      trackClick,
    } = this.props;

    const {
      moreAction,
      resultsSorry,
    } = params;

    if (searchResults.length === 0 && !showNoSearchResults && isGlobalSearch) {
      return (
        <div
          className={suitcss({
            descendantName: 'noResults',
          }, this)}
        >
          <MediaImage src="/files/icons/icon-search-no-result.svg" alt="no results" />
          <TextHeadline bold element="h2" size="m" utilities={['marginTop']}>
            {resultsSorry}
          </TextHeadline>
        </div>
      );
    }

    if (searchResults.length === 0 || showNoSearchResults) {
      return null;
    }

    return (
      <SearchResultList
        isGlobalSearch={isGlobalSearch}
        searchResults={searchResults}
        searchMetaInfo={searchMetaInfo}
        moreAction={moreAction}
        resultsSorry={resultsSorry}
        userInteraction={userInteraction}
        preSelectedFacet={preSelectedFacet}
        trackClick={trackClick}
      />
    );

  }

  renderSearchSuggestion() {
    const { searchSuggestionsData, searchSuggestionsMeta, isGlobalSearch } = this.props;
    const { counts } = searchSuggestionsMeta;

    // returns if no suggestion api call was executed
    if (!counts) {
      return;
    }
    // get available facets key names
    const availableFacets = Object.keys(counts.facets);
    const sortedSuggestions = this.sortSuggestionsByFacet(availableFacets, searchSuggestionsData);
    const facetMarkup = {};
    // eslint-disable-next-line no-return-assign
    availableFacets.forEach((facet, index) =>
      facetMarkup[facet] = this.createSuggestionsByFacet(
        facet,
        searchSuggestionsMeta.counts.facets[facet],
        sortedSuggestions[facet],
        index,
      ));
    return (
      <div
        className={suitcss({ descendantName: 'globalSearchSuggestions' }, this)}
      >
        <div
          className={suitcss({
            descendantName: 'columnBox',
            modifiers: !isGlobalSearch && ['serviceSearchColumnBox'],
          }, this)}
        >
          {isGlobalSearch ? Object.values(facetMarkup).map(facet => facet) : facetMarkup.service}
        </div>
      </div>
    );
  }

  renderSearchSuggestionsHelper(suggestions) {
    const { triggerPageTrackingForLinkClick } = this.props;
    return (
      suggestions.map((suggestionColumn, id) => (
        <ul
          className={suitcss({
            descendantName: 'cmsItems',
          }, this)}
          /* eslint-disable-next-line react/no-array-index-key */
          key={id}
        >
          {suggestionColumn.map((cmsSearchSuggestion, index) => (
            <li
              /* eslint-disable-next-line react/no-array-index-key */
              key={index}
            >
              <div className={suitcss({ descendantName: 'cmsItem' }, this)}>
                <Link
                  className={suitcss({ utilities: ['flex', 'itemsCenter'] })}
                  icon={cmsSearchSuggestion.icon}
                  to={cmsSearchSuggestion.url}
                  raw
                  withoutArrow
                  withoutStyle
                  onClick={() => {
                    triggerPageTrackingForLinkClick('CMS');
                    this.onCloseLightbox();
                  }}
                >
                  <TextCopy className={suitcss({ descendantName: 'headline', modifiers: ['raw'] }, this)}>
                    {cmsSearchSuggestion.title}
                  </TextCopy>
                </Link>
              </div>
            </li>
          ))}
        </ul>
      ))
    );
  }

  renderCmsSearchSuggestions() {
    const { cmsSearchSuggestions, isGlobalSearch, ui } = this.props;
    const middleOfSuggestionsArray = Math.ceil(cmsSearchSuggestions.length / 2);
    const cmsSuggestionFirstColumn = cmsSearchSuggestions.slice(0, middleOfSuggestionsArray);
    const cmsSuggestionSecondColumn =
      cmsSearchSuggestions.slice(((cmsSearchSuggestions.length) / 2) * (-1));
    return (
      <div
        className={suitcss({ descendantName: 'cmsSuggestions' }, this)}
      >
        <TextCopy
          className={suitcss({ descendantName: 'cmsSuggestionsHeadline' }, this)}
        >
          {ui.guiSearchHeadlineQuickLinks}
        </TextCopy>
        <div
          className={suitcss({
            descendantName: 'cmsItemsBox',
          }, this)}
        >
          {isGlobalSearch ? this.renderSearchSuggestionsHelper(
            [cmsSuggestionFirstColumn, cmsSuggestionSecondColumn],
            ) : this.renderSearchSuggestionsHelper([cmsSearchSuggestions])}
        </div>
      </div>
    );
  }

  renderSearchQueryForLightbox() {
    const {
      isGlobalSearch,
      isMediaS,
      onSubmit,
      inputValue,
      onInputChange,
      params,
      mainNavItems,
      flushSearchQuery,
    } = this.props;

    const clearInput = () => {
      document.getElementById('input').value = '';
      flushSearchQuery();
    };

    const logo = mainNavItems[0];
    return (
      <>
        <div className={suitcss({
          descendantName: 'oteloLogo',
        }, this)}
        >
          {isGlobalSearch && !isMediaS && (
            <Link
              to={logo.url}
              icon={logo.icon}
              withoutStyle
            />
          )}
        </div>
        <div
          className={suitcss({
            descendantName: 'externSearchQuery',
          }, this)}
        >
          <div
            className={suitcss({
              componentName: 'queryInput',
              modifiers: isGlobalSearch && ['globalSearchQueryInput'],
            }, this)}
            key={componentName}
            onSubmit={() => {
              this.onCloseLightbox();
              return onSubmit;
            }}
          >
            <form>
              <input
                autoFocus="autoFocus"
                type="text"
                id="input"
                value={inputValue}
                onChange={onInputChange}
                onKeyDown={this.onUserInteraction}
                placeholder={params.inputPlaceholder}
                minLength={2}
                required
              />
              <span
                onClick={clearInput}
                className={suitcss({
                  descendantName: 'clearInput',
                  modifiers: isGlobalSearch && ['inverted'],
                }, this)}
              >
                <SvgLoader path="/icons/content-close.svg" />
              </span>
            </form>
          </div>
          <Link
            className={suitcss({
              componentName,
              modifiers: isGlobalSearch && ['isGlobalSearch'],
              utilities: ['marginLeftS'],
            }, this)}
            element="button"
            onClick={this.onCancelLightbox}
            withoutStyle
            aria-label="Abbrechen"
          >
            Abbrechen
          </Link>
        </div>
      </>
    );
  }

  renderSuggestionLightBox() {
    const {
      showCmsSearchSuggestions,
      searchSuggestionsMeta,
      ui,
      isGlobalSearch,
      onSubmit,
    } = this.props;

    const { facets } = searchSuggestionsMeta.counts;
    const { userInteraction, isVisible } = this.state;
    const numberSearchResults = ui.guiSearchNResultsAction.replace(
      '{RESULTS}',
      `${searchSuggestionsMeta.counts.facets.service}`,
    );

    // Determine what to render inside the inner box
    const shouldRenderCmsSuggestions = !userInteraction
      || showCmsSearchSuggestions
      || (!isGlobalSearch && facets.service === 0);

    const lightboxContent = shouldRenderCmsSuggestions
      ? this.renderCmsSearchSuggestions()
      : this.renderSearchSuggestion();

    // Condition for displaying the "More Results" button
    const showMoreResultsButton = !isGlobalSearch
      && userInteraction
      && !showCmsSearchSuggestions
      && facets.service > 0;


    return (
      <div
        key="lightbox-inner"
        className={suitcss({
          descendantName: 'searchFormSuggestionBox',
        }, this)}
      >
        <Lightbox
          className={suitcss({
            modifiers: isGlobalSearch && ['isGlobalSearch'],

          }, this)}
          isVisible={isVisible}
          onShow={this.onOpenLightbox}
          onClose={this.onCloseLightbox}
          alignV={isGlobalSearch ? 'top' : null}
          withScrolling={false}
        >
          <form onSubmit={onSubmit} key="search-suggestion-lightbox-form">
            {this.renderSearchQueryForLightbox()}
            <div
              className={suitcss({
              descendantName: 'lightboxContent',
              modifiers: ['serviceStyle'],
            }, this)}
            >
              <div
                className={suitcss({
                componentName,
                descendantName: 'mostWanted',
                modifiers: !isGlobalSearch && ['mostWantedServiceSearch'],
              }, this)}
              >
                <div
                  className={suitcss({
                  componentName,
                  descendantName: 'queryInputLightbox',
                }, this)}
                />
                <div
                  className={suitcss({
                  componentName,
                  descendantName: 'innerBox',
                  modifiers: !isGlobalSearch && ['serviceSearchInnerBox'],
                }, this)}
                >
                  {lightboxContent}
                </div>
                {showMoreResultsButton && (
                  <div
                    className={suitcss({
                      componentName,
                      descendantName: 'moreResultsButton',
                    }, this)}
                  >
                    <Link
                      asButton
                      withoutArrow
                      element="button"
                      buttonFilled
                      onClick={() => {
                        this.onCloseLightbox();
                      }}
                    >
                      {numberSearchResults}
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </form>
        </Lightbox>
      </div>
    );
  }

  //------------------------------------------------------------------------------------------------
  //  Rendering
  //------------------------------------------------------------------------------------------------

  render() {
    const { isGlobalSearch, isSearchResultPage, isCMSConfigured } = this.props;
    return (
      <GlobalSection hasNoGlobalInner={!isSearchResultPage}>
        <section
          className={suitcss({
            componentName,
          }, this)}
        >
          {(!isGlobalSearch || isCMSConfigured) && this.renderSearchResultsForm()}
          {this.renderSuggestionLightBox()}
          {this.renderSearchResultItems()}
        </section>
      </GlobalSection>
    );
  }
}

//------------------------------------------------------------------------------------------------
//  Proptypes
//------------------------------------------------------------------------------------------------

SearchForm.propTypes = {
  onInputChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  searchResults: PropTypes.array,
  preSelectedFacet: PropTypes.string,
  inputValue: PropTypes.string.isRequired,
  userInteraction: PropTypes.bool.isRequired,
  searchSuggestionsData: PropTypes.array.isRequired,
  searchSuggestionsMeta: PropTypes.object.isRequired,
  cmsSearchSuggestions: PropTypes.array.isRequired,
  showCmsSearchSuggestions: PropTypes.bool.isRequired,
  isGlobalSearch: PropTypes.bool.isRequired,
  searchMetaInfo: PropTypes.object,
  mainNavItems: PropTypes.array.isRequired,
  flushSearchQuery: PropTypes.func.isRequired,
  isMediaS: PropTypes.bool.isRequired,
  showNoSearchResults: PropTypes.bool.isRequired,
  selectSearchTypeForTracking: PropTypes.func.isRequired,
  triggerPageTrackingForLinkClick: PropTypes.func.isRequired,
  setPreSelectedFacet: PropTypes.func.isRequired,
  trackClick: PropTypes.func.isRequired,
  isCMSConfigured: PropTypes.bool.isRequired,
  isSearchResultPage: PropTypes.bool.isRequired,
  ui: PropTypes.shape({
    guiSearchNResultsAction: PropTypes.string.isRequired,
    guiSearchHeadlineQuickLinks: PropTypes.string.isRequired,
    guiSearchNResults: PropTypes.string.isRequired,
  }),
};

export default connectUI()(SearchForm);
