import React, { Component } from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';
import { capitalize } from '../../../helpers/str';

import Headline from '../../basics/text/TextHeadline';
import Copy from '../../basics/text/TextCopy';
import Slider from '../../basics/slider/Slider';
import SliderSlide from '../../basics/slider/SliderSlide';

const sliderConfig = {
  slidesPerView: 1,
  spaceBetween: 0,
  autoplay: { delay: 4000 },
  loop: true,
};

class TeaserWrapper extends Component {

  static getColumnsFromCount(count) {
    const maxCols = 12;
    if (count < 1) {
      return 12;
    } else if (count > 4) {
      return 12 / 3;
    }
    return Math.ceil(maxCols / count);
  }

  static getImageSizeFromCount(count) {
    let ret;
    switch (count) {
      case 1:
        ret = 'full';
        break;
      case 2:
        ret = 'half';
        break;
      case 3:
        ret = 'third';
        break;
      case 4:
        ret = 'fourth';
        break;
      default:
        ret = 'third';
    }
    return ret;
  }

  renderSlider() {
    const { children } = this.props;
    const imageSize = TeaserWrapper.getImageSizeFromCount(1);
    return (
      <Slider
        className={suitcss({ descendantName: 'slider' }, this)}
        config={sliderConfig}
        pagination
      >
        {children.map((item, index) => (
          <SliderSlide
            className={suitcss({ descendantName: 'col' }, this)}
            key={index}
          >
            {React.cloneElement(item, { layout: imageSize })}
          </SliderSlide>
        ))}
      </Slider>
    );
  }

  renderGroup() {
    const children = this.props.children instanceof Array
      ? this.props.children
      : [this.props.children];
    const colClass = `mlCol${TeaserWrapper.getColumnsFromCount(children.length)}`;
    const imageSize = TeaserWrapper.getImageSizeFromCount(children.length);
    return (
      <ul className={suitcss({ descendantName: 'teasers' }, this)} >
        {children.map((item, index) => (
          <li
            className={suitcss({ descendantName: 'col', utilities: [colClass] }, this)}
            key={index}
          >
            {React.cloneElement(item, { layout: imageSize })}
          </li>
        ))}
      </ul>
    );
  }

  render() {
    const { type, theme, title, footer, primaryModule, adjacent, children } = this.props;
    const isBanner = type === 'banner';
    return (
      <div
        className={suitcss({
          modifiers: [
            theme && `theme${capitalize(theme)}`,
            type && `teaser${capitalize(type)}`,
            adjacent && 'adjacent',
          ],
        }, this)}
      >
        <div className={suitcss({ descendantName: 'content' }, this)} >
          {title && (
            <Headline
              className={suitcss({ descendantName: 'headline' }, this)}
              size="l"
              embedded
              element={primaryModule ? 'h1' : 'h2'}
            >
              {title}
            </Headline>
          )}
          {isBanner && children.length > 1 && this.renderSlider()}
          {(!isBanner || children.length < 2) && this.renderGroup()}
          {footer && (
            <Copy
              className={suitcss({ descendantName: 'footer' }, this)}
              size="secondary"
              raw
              embedded
            >
              {footer}
            </Copy>
          )}
        </div>
      </div>
    );
  }
}

TeaserWrapper.propTypes = {
  type: PropTypes.oneOf([
    '',
    'facts',
    'text',
    'icon_text',
    'banner',
  ]).isRequired,
  theme: PropTypes.oneOf(['', 'dark', 'light']),
  title: PropTypes.string,
  primaryModule: PropTypes.bool,
  footer: PropTypes.string,
  adjacent: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};
TeaserWrapper.defaultProps = {};

export default TeaserWrapper;
