import PageModule from './PageModule';
import {
  MARKET_ALL,
  UI_COLLECTION_PROMOTION,
} from '../../helpers/constants';
import MobileProvidersRequest from '../requests/MobileProvidersRequest';
import { sendIfNecessary, send } from '../../actions/request/send';
import FaqTopicRequest from '../requests/FaqTopicRequest';
import { isTariffEntity } from '../../helpers/entity';
import { fetchEntityById } from '../../actions/request/registry';
import CountriesRequest from '../requests/CountriesRequest';
import GdprConsentsRequest from '../requests/GdprConsentsRequest';
import PasswordValidationRulesRequest from '../requests/PasswordValidationRulesRequest';
import { checkoutNavCatcher } from '../../helpers/browserNavigation';

/**
 * Module to guide the user through the checkout process.
 * @see https://confluence.db-n.com/x/RqDc
 */
class CheckoutFormModule extends PageModule {
  constructor(moduleData) {
    super(import(/* webpackChunkName:"CheckoutForm" */ '../../containers/checkout/CheckoutForm'), moduleData);
  }

  /**
   * @override
   */
  prepareBeforeMount() {
    return async (dispatch, getState) => {
      dispatch(sendIfNecessary(new MobileProvidersRequest()));
      dispatch(send(new GdprConsentsRequest()));
      dispatch(send(new PasswordValidationRulesRequest()));

      if (checkoutNavCatcher(dispatch, getState)) {
        return;
      }
      const { cart, site } = getState();
      const process = site.contractRenewal.isInProgress ? 'contractRenewal' : 'checkout';
      const entity = await dispatch(fetchEntityById(cart.find(isTariffEntity).eid));
      let faqs;
      try {
        faqs = (await dispatch(send(new FaqTopicRequest(`${entity.market}-${process}`)))).body.data.groups;
      } catch (e) {
        // no faqs exist; silently ignore this and use empty list as fallback
        faqs = [];
      }
      const countries = (await dispatch(send(new CountriesRequest(MARKET_ALL)))).body.data;
      // eslint-disable-next-line consistent-return
      return {
        faqs,
        countries,
      };
    };
  }

  /**
   * @override
   */
  getRequiredUi() {
    return [UI_COLLECTION_PROMOTION];
  }
}

export default CheckoutFormModule;
