import { createSelector } from 'reselect';
import { devWarning } from './meta';

/**
 * Returns the eid the legal text is targeting or null if the legal text is generic
 */
export const getLegalTarget = legal => legal.tariffEid || legal.tariffOptionEid;

/**
 * A legal text without a target is considered to be generic.
 */
const isGeneric = legal => legal && !getLegalTarget(legal);

/**
 * Returns a list of visible legal entities in correct order.
 * A legal text is shown if it is referenced by a footnote or
 * is a generic legal text.
 *
 * If legal texts have not been fetched yet, null is returned
 *
 * @return null|array
 */
export const getVisibleLegals = createSelector(
  state => state.legal.legals,
  state => state.legal.references,
  state => state.entities.promotionVO,
  (legals, references, promotions) => {
    if (!legals) {
      return null;
    }
    const legalsVisible = [];
    const legalEids = Object.keys(legals);

    // first add all legals referenced by footnotes in the order
    // they occur within the footnotes list
    references.forEach(reference => {
      const referencedLegalEids = legalEids.filter(lEid =>
        reference.id === getLegalTarget(legals[lEid]));

      if (referencedLegalEids.length === 0) {
        devWarning('Missing legal text for reference', reference.id);
        return;
      }
      referencedLegalEids.forEach(legalEid => legalsVisible.push(legals[legalEid]));
    });

    // then add all generic legals
    legalEids.forEach(eid => {
      const legal = legals[eid];

      if (isGeneric(legal)) {
        legalsVisible.push(legal);
      }
    });

    // remove legals tied to promotions that aren't valid
    for (let i = legalsVisible.length - 1; i >= 0; i--) {
      const isWithPromo = Object.values(promotions)
        // eslint-disable-next-line no-loop-func
        .some(promo => legalsVisible[i].promotionIds.includes(promo.id));
      if (legalsVisible[i].promotionIds.length && !isWithPromo) {
        legalsVisible.splice(legalsVisible.indexOf(legalsVisible[i]), 1);
      }
    }

    // only include legals with a long copy
    return legalsVisible.filter(l => l.copyWeb);
  },
);
