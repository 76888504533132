import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { showSimpleDialogWithCancelButtonAndAction } from '../actions/dialog/misc';
import { _isSuccessPage, _isVVLPage, redirectToLandingPage } from './browserNavigation';

export const showNavDialog = (dispatch, site, ui, isVVL) => {
  const action = (site.contractRenewal.isInProgress || isVVL)
    ? ui.cfoNavBackDashboard
    : ui.cfoNavBackHome;
  dispatch(showSimpleDialogWithCancelButtonAndAction(
    ui.cfoOrderSuccessful,
    ui.cfoNavBack,
    action,
    '',
    () => redirectToLandingPage(dispatch, site, isVVL),
  ));
};

const SuccessPageDialog = ({ dispatch, site, ui }) => {
  useEffect(() => {
    if (!_isSuccessPage(site.routing.currentLocation.pathname, site.sitemap)) {
      return () => {};
    }
    if (site.routing.currentLocation.query.showInfo) {
      const isVVL = _isVVLPage(site.routing.currentLocation.pathname, site.sitemap);
      showNavDialog(dispatch, site, ui, isVVL);
      return () => {};
    }
    return () => {};
  }, []);
  return <></>;
};
SuccessPageDialog.propTypes = {
  dispatch: PropTypes.func,
  site: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
};

const mapStateToProps = ({ site, ui }) => ({
  site,
  ui,
});

const mapDispatchToProps = dispatch => ({ dispatch });
export default connect(mapStateToProps, mapDispatchToProps)(SuccessPageDialog);
