/* global document, window */
import smoothScroll from 'smoothscroll';
import debounce from 'just-debounce';

export const NAVIGATION_MAIN_SIZE = 54;
export const NAVIGATION_MAIN_FACELIFT_SIZE = 70;
export const NAVIGATION_MAIN_FACELIFT_SCROLLED_SIZE = NAVIGATION_MAIN_SIZE;
export const NAVIGATION_MAIN_FACELIFT_TURNING_POINT = 95;
export const NAVIGATION_TOPBAR_SIZE = 40;
export const NAVIGATION_FULL_SIZE = NAVIGATION_MAIN_SIZE + NAVIGATION_TOPBAR_SIZE;

export const getStickyContentHeight = () => {
  let height = 0;
  if (process.browser) {
    [...document.querySelectorAll('.ServiceStickyContent-canvas')].forEach(el => {
      // Compensate for sticky content heights + transforms if fixed
      if (el.style.position === 'fixed') {
        const bounds = el.getBoundingClientRect();
        height += bounds.height + bounds.top;
      }
    });
  }

  return height;
};

export const getHeaderHeight = () => {
  let height = 0;
  if (process.browser) {
    const selectors = ['.ServiceStickyContent--header .ServiceStickyContent-canvas'];
    selectors.forEach(selector => {
      const el = [...document.querySelectorAll(selector)][0];
      if (el) {
        height += el.clientHeight;
      }
    });
  }

  return height;
};

export const scrollToPos = (pos, config) => {
  if (process.browser) {
    const scrollToPadding = 30;
    const settings = {
      offset: scrollToPadding + NAVIGATION_MAIN_SIZE,
      contentAware: true,
      delay: 100,
      ...(config || {}),
    };
    // use timeout to ensure dom has updated (accordions)
    setTimeout(() => {
      const stickyContentHeight = settings.contentAware ? getStickyContentHeight() : 0;
      const oy = stickyContentHeight + settings.offset;
      smoothScroll((pos) - oy);
    }, settings.delay);
  }
};

export const scrollToTop = () => {
  if (process.browser) {
    scrollToPos(0, { offset: 0, contentAware: false });
  }
};

export const scrollToElement = debounce((el, config) => {
  if (el) {
    // console.debug('%c scrollToElement', 'background: #222; color: #bada55', el);
    scrollToPos(el.getBoundingClientRect().top + window.pageYOffset, config);
  }
}, 50);

const isElementOverlapped = (element) => {
  if (!element) return false;

  // return false if skip link - because it is part of the header
  if (element.classList.contains('SkipLink-link')) {
    return false;
  }

  // return false if element is checkbox or radiobutton with opacity 0
  if (element.tagName === 'INPUT' && (element.type === 'checkbox' || element.type === 'radio')) {
    const computedStyle = window.getComputedStyle(element);
    if (computedStyle.opacity === '0') {
      return false;
    }
  }

  const rect = element.getBoundingClientRect();

  // return true if element is out of viewport
  if (rect.top < 0 || rect.bottom > window.innerHeight) {
    return true;
  }

  // Only do the more expensive elementFromPoint check
  // if element is in a potentially problematic position
  const centerX = rect.left + rect.width / 2;
  const centerY = rect.top + rect.height / 2;
  const elementAtPoint = document.elementFromPoint(centerX, centerY);

  return elementAtPoint && !element.contains(elementAtPoint);
};

export const scrollElementIntoViewIfOverlapped = debounce((element) => {
  if (!element || !process.browser) return;

  if (isElementOverlapped(element)) {
    scrollToElement(element);
  }
}, 50); // Small delay to batch rapid focus events

export const scrollToHash = (hash = '', config) => {
  if (process.browser && hash) {
    const el = document.querySelector(hash);
    scrollToElement(el, config);
  }
};

// helper function to ensure that the target is valid
// * bug - input field auto complete fires an
// unrelated event on mouseLeave
// * fix (mouseout) - filter related targets till either related
// is the current target (meaning the event is from within)
// or is not the current target (meaning we've left the target)
// TRUE MOUSE OOOOOOOUTTT!

export const hasMouseLeftTarget = (evt) => {
  const { target } = evt;
  let related = evt.relatedTarget;
  while (
    related
    && related !== target
    && related.nodeName !== 'BODY'
  ) {
    related = related.parentNode;
  }
  return related && related !== target;
};

/* Prevent / Allow document scrolling */
export const toggleDocumentScrolling = (blocked, resizeToViewport) => {
  if (process.browser) {
    const el = document.querySelector('[data-app="main"]');
    // scroll to top - if blocking set container height / ios body:hidden bug
    if (resizeToViewport) {
      scrollToTop();
      el.style.height = blocked ? `${window.innerHeight}px` : '';
      el.style.overflow = blocked ? 'hidden' : '';
    } else if (!blocked) {
      el.style.height = '';
      el.style.overflow = '';
    }
    // remove or set no-scroll class
    if (blocked) {
      document.documentElement.classList.add('no-scroll');
    } else {
      document.documentElement.classList.remove('no-scroll');
    }
  }
};
