/**
 * @file
 *
 * This reducer stores data that is strictly user-related,
 * e.g. payment data, booked items, credentials etc.
 */

import { LOCATION_CHANGE } from 'react-router-redux';
import {
  REGISTER_VOID,
  REGISTER_CID,
  REGISTER_PROMOTION_CODES,
  REGISTER_AFFILIATE_ID,
  RECEIVE_AFFILIATE_PARTNER_LINK,
  RECEIVE_PPLUS_DATA,
  REGISTER_ORDER_BY_AGENT,
  REGISTER_DENIED_PROMOTIONS,
  REMOVE_DENIED_PROMOTIONS,
  REGISTER_CAMPAIGN_TOKEN,
  REMOVE_CAMPAIGN_TOKEN,
  REMOVE_PROMOTION_CODES,
  REGISTER_GRANTED_PROMOTIONS,
  REGISTER_GRANTED_CAMPAIGN_PROMOTIONS,
} from '../actions/user/marketing';
import { CONFIRM_ORDER } from '../actions/order/completed';
import {
  isTariffBookable,
  isTariffRenewable,
  isTariffChangeable,
  isTariffBooked,
} from '../helpers/user';
import { getQueryParam, getUrlPath } from '../helpers/url';
import {
  STORAGE_KEY_CREDENTIALS,
  STORAGE_KEY_AFFILIATE,
  STORAGE_KEY_LAST_VISIT,
  STORAGE_KEY_DOCUMENT_STATES,
  STORAGE_KEY_SESSION_ID,
  BRAND_OTELO,
  STORAGE_KEY_UMID,
  REQUEST_METHOD_POST,
} from '../helpers/constants';
import * as storage from '../helpers/storage';
import { SYNC_STORAGE } from '../actions/global/storage';
import {
  REQUEST_RECEIVED_RESPONSE,
  REQUEST_RECEIVED_ERROR,
} from '../actions/request/basic';
import { RECEIVED_CREDENTIALS, CLEAR_NONCE, SET_SCOPE } from '../actions/user/login';
import { LOGOUT } from '../actions/user/logout';
import {
  CLEAR_USER_DATA,
  ACCOUNT_USER_CONTRACT,
  ACCOUNT_USER_CUSTOMER_DATA,
  ACCOUNT_USER_PAYMENT_DATA,
  ACCOUNT_USER_ACR_SETTING,
  ACCOUNT_USER_EVN_SETTING,
  ACCOUNT_USER_BILLING,
  ACCOUNT_USER_GDPR_CONSENTS,
  ACCOUNT_USER_GDPR_CONSENTS_INQUIRY,
  ACCOUNT_USER_ADCONSENT,
  ACCOUNT_USER_GDPR_DATA, ACCOUNT_USER_MNP_OUT,
} from '../actions/user/clear';
import { STORE_PASSWORD, DOCUMENT_READ, DOCUMENT_OPEN } from '../actions/user/inbox';
import { UPDATE_CREDENTIALS } from '../actions/user/credentials';
import { UPDATE_SELECTED_OPTIONAL_PROMOTIONS } from '../actions/user/promotions';
import { UPDATE_CONTRACT_DATA_ESIM_STATUS, SHOW_ESIM_STATUS_REFRESH } from '../actions/user/eSim';
import ArticleInformationGroupsRequest from '../model/requests/ArticleInformationGroupsRequest';
import BalanceRequest from '../model/requests/BalanceRequest';
import BookingOverviewRequest from '../model/requests/BookingOverviewRequest';
import ConsumptionsRequest from '../model/requests/ConsumptionsRequest';
import EECCBMnpPortingInitiateRequest from '../model/requests/EECCBMnpPortingInitiateRequest';
import TariffSummaryRequest from '../model/requests/TariffSummaryRequest';
import TariffOptionsRequest from '../model/requests/TariffOptionsRequest';
import MyPromotionsRequest from '../model/requests/MyPromotionsRequest';
import FlatratesRequest from '../model/requests/FlatratesRequest';
import TopupStatusRequest from '../model/requests/TopupStatusRequest';
import LoginRequest from '../model/requests/LoginRequest';
import ReauthRequest from '../model/requests/ReauthRequest';
import CustomerDataRequest from '../model/requests/CustomerDataRequest';
import ContractDataRequest from '../model/requests/ContractDataRequest';
import AccountGdprConsentsRequest from '../model/requests/AccountGdprConsentsRequest';
import AccountGdprConsentsAggregatedRequest from '../model/requests/AccountGdprConsentsAggregatedRequest';
import AccountGdprConsentsInquiryRequest from '../model/requests/AccountGdprConsentsInquiryRequest';
import AccountDataPortabilityRequest from '../model/requests/AccountDataPortabilityRequest';
import EvnSettingRequest from '../model/requests/EvnSettingRequest';
import PaymentDataRequest from '../model/requests/PaymentDataRequest';
import TariffsRequest from '../model/requests/TariffsRequest';
import PromotionsRequest from '../model/requests/PromotionsRequest';
import SimReplStatusRequest from '../model/requests/SimReplStatusRequest';
import SimStatusRequest from '../model/requests/SimStatusRequest';
import SimCardRequest from '../model/requests/SimCardRequest';
import DocumentsRequest from '../model/requests/DocumentsRequest';
import TariffNotificationRequest from '../model/requests/TariffNotificationRequest';
import ApiError from '../model/errors/ApiError';
import PromoBannerRequest from '../model/requests/BannerRotationRequest';
import QueueableRequest, { getShortenedUuid } from '../model/requests/QueueableRequest';
import MnpOutRequest from '../model/requests/MnpOutRequest';
import UmidInfoRequest from '../model/requests/UmidInfoRequest';
import EmailValidationRequest from '../model/requests/EmailValidationRequest';
import ACRSettingsRequest from '../model/requests/ACRSettingsRequest';
import CumulativeConsumptionRequest from '../model/requests/CumulativeConsumptionRequest';
import NbaOfferRequest from '../model/requests/NbaOfferRequest';
import { parseScope } from '../helpers/scope';
import { isGrantedCampaignPromotion, isGrantedPromotion } from '../helpers/promotions';
import { camelCase, sanitizeString } from '../helpers/str';
import EECCOrderingInitiateRequest from '../model/requests/EECCOrderingInitiateRequest';
import EECCActivationInitiateRequest from '../model/requests/EECCActivationInitiateRequest';
import EECCTariffChangeInitiateRequest from '../model/requests/EECCTariffChangeInitiateRequest';
import { YEAR } from '../helpers/date';
import EECCPromotionsOrderInitiateRequest
  from '../model/requests/EECCPromotionsOrderInitiateRequest';
import SimCardESimInformationRequest from '../model/requests/SimCardESimInformationRequest';
import SimCardESimQRRequest from '../model/requests/SimCardESimQRRequest';
import SimCardESimStatusRequest from '../model/requests/SimCardESimStatusRequest';
import ESimNotificationReadyToPairRequest from '../model/requests/ESimNotificationReadyToPairRequest';
import { UPDATE_CART_SIM_TYPE } from '../actions/order/cart';

const getAvatarFromResponse = ({ avatarId, avatarUrl }) => (avatarId && avatarUrl
  ? { id: avatarId, url: getUrlPath(`${avatarUrl}.svg`) }
  : null);

/**
 * Updates the credentials in case we received an error or response.
 *
 * Note: some errors may not extend ApiError and we cannot access the fullResponse,
 * which means we will not be able to update credentials in this case.
 *
 * @param {object} credentials - the credentials as currently present in the redux-state
 * @param {object} action
 * @return {object}
 */
const updateCredentials = (credentials, action) => {
  const { payload } = action;
  const response = (
    payload.response || (payload.error instanceof ApiError && payload.error.fullResponse)
  );

  if (!response) {
    return credentials;
  }

  const nonce = response.headers.get('x-nonce');
  return nonce ? { ...credentials, nonce } : credentials;
};

/**
 * Stores data received from login response.
 */
const integrateLoginData = (state, { payload }) => {
  const { body, headers } = payload.response;
  const {
    userType: market,
    msisdn,
    name,
    phoneNumber,
    brand: brandName,
    dunningLevel,
    scope,
  } = body.data;
  return {
    ...state,
    avatar: getAvatarFromResponse(body.data),
    credentials: {
      msisdn,
      name,
      phoneNumber,
      market,
      brandName: brandName || BRAND_OTELO,
      nonce: headers.get('x-nonce'),
    },
    market,
    name,
    phoneNumber,
    brandName,
    dunningLevel,
    scope: parseScope(scope),
  };
};

const setServiceOptions = serviceOptions => {
  const result = {};
  serviceOptions.forEach(option => {
    result[camelCase(option.name)] = option.value;
    return true;
  });
  return result;
};

const integrateTariffs = tariffsVO => ({
  bookedTariffs: tariffsVO.filter(isTariffBooked).map(tariffVO => tariffVO.id),
  bookableTariffs: tariffsVO.filter(isTariffBookable).map(tariffVO => tariffVO.id),
  changeableTariffs: tariffsVO.filter(isTariffChangeable).map(tariffVO => tariffVO.id),
  renewableTariffs: tariffsVO.filter(isTariffRenewable).map(tariffVO => tariffVO.id),
});

const integrateTariffOptions = tariffOptionsVO => ({
  bookedTariffOptions: tariffOptionsVO.filter(el => el.booked).map(el => el.id),
  bookableTariffOptions: tariffOptionsVO.filter(el => el.bookable).map(el => el.id),
});

/**
 * This function gives us control over how to integrates the received
 * response data in the store.
 *
 * For example a response received from the balance endpoint is stored
 * in the store under `user.balance`.
 */
const integrateResponseData = (state, action) => {
  const { meta, payload } = action;
  const { body } = payload.response;
  const { request } = meta;
  const updates = {
    credentials: updateCredentials(state.credentials, action),
  };

  if (request instanceof ReauthRequest) {
    const merged = { ...state, ...updates };
    const credentials = {
      ...merged.credentials,
    };
    const avatar = getAvatarFromResponse(body.data);
    const scope = parseScope(body.data.scope).length ? parseScope(body.data.scope) : state.scope;
    return {
      ...merged,
      credentials,
      avatar,
      scope,
    };
  }

  if (request instanceof LoginRequest) {
    return integrateLoginData(state, action);
  }

  if (request instanceof UmidInfoRequest) {
    if (request.method === REQUEST_METHOD_POST) {
      return state;
    }
    storage.setItem(storage.STORAGE_TYPE_COOKIE, STORAGE_KEY_UMID, body.data.identifier, {
      expires: new Date(Date.now() + YEAR),
      path: '/',
    });

    return {
      ...state,
      gdprPermissions: body.data.permissions,
      gdprNotifications: body.data.notifications,
    };
  }

  if (request instanceof AccountGdprConsentsInquiryRequest) {
    return {
      ...state,
      ...updates,
      gdprConsentsInquiry: body.data,
    };
  }

  if (request instanceof AccountGdprConsentsAggregatedRequest) {
    return { ...state, ...updates, adConsent: body.data };
  }

  if (request instanceof EECCOrderingInitiateRequest) {
    return {
      ...state,
      ...updates,
      EECC: {
        ...state.EECC,
        ordering: {
          processId: body.data.processId,
        },
      },
    };
  }

  if (request instanceof EECCActivationInitiateRequest) {
    return {
      ...state,
      ...updates,
      EECC: {
        ...state.EECC,
        activation: {
          processId: body.data.processId,
        },
      },
    };
  }

  if (request instanceof EECCTariffChangeInitiateRequest) {
    return {
      ...state,
      ...updates,
      EECC: {
        ...state.EECC,
        tariffChange: {
          processId: body.data.processId,
        },
      },
    };
  }

  if (request instanceof EECCPromotionsOrderInitiateRequest) {
    return {
      ...state,
      ...updates,
      EECC: {
        ...state.EECC,
        promotion: {
          processId: body.data.processId,
        },
      },
    };
  }

  if (request instanceof EECCBMnpPortingInitiateRequest) {
    return {
      ...state,
      ...updates,
      EECC: {
        ...state.EECC,
        bmnp: {
          processId: body.data.processId,
        },
      },
    };
  }

  if (request.isGET()) {
    if (request instanceof PromoBannerRequest) {
      return {
        ...state,
        ...updates,
        banners: body.data,
      };
    }
    if (request instanceof NbaOfferRequest) {
      return {
        ...state,
        ...updates,
        nbaOffer: body.data,
      };
    }
    if (request instanceof DocumentsRequest) {
      const stateDocuments = state.inbox.documents
        && state.inbox.documents.filter(d => d.alreadyAccessed);
      const bodyDocuments = body.data && body.data.filter(d => d.alreadyAccessed);
      return {
        ...state,
        ...updates,
        inbox: {
          ...state.inbox,
          documents: stateDocuments && stateDocuments.length > bodyDocuments.length
            ? state.inbox.documents : body.data,
        },
      };
    }
    if (request instanceof PaymentDataRequest) {
      return {
        ...state,
        ...updates,
        paymentData: body.data,
      };
    }
    if (request instanceof EvnSettingRequest) {
      return {
        ...state,
        ...updates,
        evnSetting: body.data,
      };
    }
    if (request instanceof AccountGdprConsentsRequest) {
      return {
        ...state,
        ...updates,
        gdprConsents: body.data,
      };
    }
    if (request instanceof AccountGdprConsentsAggregatedRequest) {
      return {
        ...state,
        ...updates,
        adConsent: body.data,
      };
    }
    if (request instanceof AccountGdprConsentsInquiryRequest) {
      return {
        ...state,
        ...updates,
        gdprConsentsInquiry: body.data,
      };
    }
    if (request instanceof AccountDataPortabilityRequest
      && request.responseDataType === QueueableRequest.RESPONSE_DATA_TYPE_JSON) {
      return {
        ...state,
        ...updates,
        gdprData: body.data,
      };
    }
    if (request instanceof ContractDataRequest) {
      return {
        ...state,
        ...updates,
        contractData: body.data,
      };
    }
    if (request instanceof ACRSettingsRequest) {
      return {
        ...state,
        ...updates,
        serviceOptions: setServiceOptions(body.data),
      };
    }
    if (request instanceof EmailValidationRequest) {
      return {
        ...state,
        ...updates,
        customerData: {
          ...state.customerData,
          ...body.data,
        },
      };
    }
    if (request instanceof MnpOutRequest) {
      return {
        ...state,
        ...updates,
        mnpOutData: {
          ...body.data,
          status: 200,
        },
      };
    }
    if (request instanceof CustomerDataRequest) {
      if (body.data) {
        const cleanBodyData = {};
        const responseKeys = Object.keys(body.data);

        for (let i = 0; responseKeys.length > i; i++) {
          const keyValue = body.data[responseKeys[i]];
          if (typeof keyValue === 'string') {
            cleanBodyData[responseKeys[i]] = sanitizeString(keyValue);
          } else {
            cleanBodyData[responseKeys[i]] = keyValue;
          }
        }
      }

      const avatar = getAvatarFromResponse(body.data);
      return {
        ...state,
        ...updates,
        avatar,
        customerData: state.customerData ? { ...state.customerData, ...body.data } : body.data,
        scope: parseScope(body.data.scope),
        dunningLevel: body.data.dunningLevel,
      };
    }
    if (request instanceof BalanceRequest) {
      return { ...state, ...updates, balance: body.data };
    }
    if (request instanceof ConsumptionsRequest) {
      return {
        ...state,
        ...updates,
        ...{
          consumptions: body.data.consumptionSummary,
          consumptionsIndividual: body.data.individualCycle,
        },
      };
    }
    if (request instanceof TariffSummaryRequest) {
      return {
        ...state,
        ...updates,
        tariffSummary: body.data,
      };
    }
    if (request instanceof BookingOverviewRequest) {
      return {
        ...state,
        ...updates,
        bookingOverview: body.data,
      };
    }
    if (request instanceof TariffsRequest) {
      return { ...state, ...updates, ...integrateTariffs(body.data) };
    }
    if (request instanceof TariffOptionsRequest) {
      return { ...state, ...updates, ...integrateTariffOptions(body.data) };
    }
    if (request instanceof MyPromotionsRequest) {
      // according to DBNHAMOP-4465 vvl should be able to accept promoCodes
      // we need to filter the grantedPromotions with the promoCode from the bookable promotions
      const withPromoCode = request.url.includes('promoCode=');
      const bookablePromotions = (withPromoCode
        ? body.data.filter(promo => !isGrantedPromotion(promo))
        : body.data).map(vo => vo.eid);
      return { ...state, ...updates, bookablePromotions };
    }
    if (request instanceof PromotionsRequest && (request.url.includes('promoCode=') || request.url.includes('campaignToken='))) {
      return {
        ...state,
        ...updates,
        grantedCampaignPromotions: Object.values(body.data)
          .filter(promo => isGrantedCampaignPromotion(promo))
          .map(promo => promo.eid),
      };
    }
    if (request instanceof FlatratesRequest) {
      return { ...state, ...updates, flatrates: body.data.flatrates };
    }
    if (request instanceof TopupStatusRequest) {
      return { ...state, ...updates, topup: body.data };
    }
    if (request instanceof SimReplStatusRequest) {
      return { ...state, ...updates, simReplacementStatus: body.data };
    }
    if (request instanceof SimCardRequest) {
      const contractData = state.contractData || {};
      contractData.simCard = body.data;
      return { ...state, ...updates, contractData };
    }
    if (request instanceof TariffNotificationRequest) {
      const { notifications } = state;
      notifications[request.notificationId] = body.data;
      return { ...state, ...updates, notifications };
    }
    if (request instanceof SimStatusRequest) {
      return { ...state, ...updates, simStatusResponse: { msisdn: request.msisdn, ...body.data } };
    }
    if (request instanceof CumulativeConsumptionRequest) {
      return { ...state, ...updates, cumulativeConsumption: body.data };
    }
    if (request instanceof ArticleInformationGroupsRequest) {
      return { ...state, ...updates, articleInformationGroups: body.data };
    }
    if (request instanceof SimCardESimInformationRequest) {
      return { ...state, ...updates, eSim: { ...state.eSim, information: body.data } };
    }
    if (request instanceof SimCardESimQRRequest) {
      return { ...state, ...updates, eSim: { ...state.eSim, qr: body.data } };
    }
    if (request instanceof SimCardESimStatusRequest) {
      return {
        ...state,
        ...updates,
        eSim: { ...state.eSim, status: body.data },
      };
    }
    if (request instanceof ESimNotificationReadyToPairRequest) {
      return { ...state, ...updates, eSim: { ...state.eSim, readyToPair: body.data.readyToPair } };
    }
  }

  // store the new credentials in every case
  return { ...state, ...updates };
};

/**
 * When receiving an error, we might need to update the nonce if the
 * error stems from a secured endpoint.
 */
const integrateErrorResponseData = (state, action) => {
  const { meta } = action;
  const { request } = meta;

  return {
    ...state,
    credentials: updateCredentials(state.credentials, action),
    ...(request instanceof MnpOutRequest ? { mnpOutData: { status: 404 } } : {}),
  };
};

const defaultState = (isLogout) => {
  if (!storage.getItem(storage.STORAGE_TYPE_SESSION_STORAGE, STORAGE_KEY_SESSION_ID)) {
    storage.setItem(storage.STORAGE_TYPE_SESSION_STORAGE, STORAGE_KEY_SESSION_ID, getShortenedUuid('FE_SESSION_'));
  }
  const credentials = isLogout ? {} : (
      storage.getItem(storage.STORAGE_TYPE_COOKIE, STORAGE_KEY_CREDENTIALS)
      || storage.getItem(storage.STORAGE_TYPE_SESSION_STORAGE, STORAGE_KEY_CREDENTIALS) || {}
    );
  return {
    lastVisit: parseInt(storage.getItem(storage.STORAGE_TYPE_COOKIE, STORAGE_KEY_LAST_VISIT), 10),
    // 11210007 is the default bId that is used if no other id is given
    bId: getQueryParam('b_id') || '11210007',
    affId: storage.getItem(storage.STORAGE_TYPE_COOKIE, STORAGE_KEY_AFFILIATE),
    authenticated: false,
    // promo codes are only stored in the session.
    promoCodes: [],
    campaignToken: [],
    cartSimType: null,
    promotions: {}, // @todo use array instead of object
    avatar: null,
    customerData: null,
    paymentData: null,
    contractData: undefined,
    evnSetting: null,
    adConsent: null,
    gdprConsentsInquiry: {},
    gdprConsents: null,
    gdprData: null,
    gdprPermissions: {},
    gdprNotifications: {},
    balance: null,
    EECC: {
      activation: {
        processId: null,
      },
      ordering: {
        processId: null,
      },
      tariffChange: {
        processId: null,
      },
      promotion: {
        processId: null,
      },
      bmnp: {
        processId: null,
      },
    },
    consumptions: null,
    tariffSummary: null,
    bookedTariffs: [],
    bookedTariffOptions: [],
    bookableTariffOptions: [],
    bookableTariffs: [],
    changeableTariffs: [],
    renewableTariffs: [],
    bookedOptions: null,
    bookableOptions: null,
    bookablePromotions: [],
    selectedOptionalPromotions: [], // promotions selected by customer
    grantedPromotions: {
      promotions: [],
      promoCodes: [],
    },
    grantedCampaignPromotions: [],
    deniedPromotions: {
      promoCodes: [],
    },
    serviceOptions: null,
    flatrates: null,
    topup: null,
    password: null,
    name: credentials.name,
    market: credentials.market,
    brandName: credentials.brandName,
    phoneNumber: credentials.phoneNumber,
    credentials,
    notifications: {},
    pplus: null,
    mnpOutData: null,
    inbox: {
      documents: storage.getItem(
        storage.STORAGE_TYPE_COOKIE, STORAGE_KEY_DOCUMENT_STATES,
      ) || null,
    },
    banners: undefined,
    nbaOffer: {},
    articleInformationGroups: [],
    eSim: {
      information: {},
      qr: {},
      status: {},
      readyToPair: false,
      statusRefresh: false,
    },
  };
};

const clearUserDataId = (state, id) => {
  switch (id) {
    case ACCOUNT_USER_PAYMENT_DATA: return { ...state, paymentData: null };
    case ACCOUNT_USER_CUSTOMER_DATA: return { ...state, customerData: null };
    case ACCOUNT_USER_CONTRACT: return { ...state, contractData: null };
    case ACCOUNT_USER_ACR_SETTING: return { ...state, serviceOptions: null };
    case ACCOUNT_USER_EVN_SETTING: return { ...state, evnSetting: null };
    case ACCOUNT_USER_GDPR_CONSENTS: return { ...state, gdprConsents: null };
    case ACCOUNT_USER_ADCONSENT: return { ...state, adConsent: null };
    case ACCOUNT_USER_GDPR_CONSENTS_INQUIRY: return { ...state, gdprConsentsInquiry: null };
    case ACCOUNT_USER_GDPR_DATA: return { ...state, gdprData: null };
    case ACCOUNT_USER_MNP_OUT: return { ...state, mnpOutData: null };
    case ACCOUNT_USER_BILLING: return state;
    default: return state;
  }
};

const clearUserDataIds = (state, ids) =>
  ids.reduce((acc, id) => clearUserDataId(acc, id), state);

function user(state = defaultState(), action = {}) {
  switch (action.type) {
    case RECEIVED_CREDENTIALS:
      return integrateLoginData(state, action);
    case CLEAR_NONCE:
      // CLEAR_NONCE is a helper to manually clear the authentication data
      // which forces the reauthentication to kick in for
      // pending or new requests that require authentication
      return {
        ...state,
        credentials: {
          ...state.credentials,
          nonce: 'foo', // set invalid nonce
        },
      };
    case LOGOUT: // eslint-disable-line
      const defaults = defaultState(true); // eslint-disable-line
      return {
        ...state,
        ...defaults,
        bId: state.bId || defaults.bId,
        inbox: {
          ...state.inbox,
          documents: null,
        },
      };
    case DOCUMENT_OPEN:
      return { ...state, inbox: { ...state.inbox, openDocument: action.payload } };
    case CLEAR_USER_DATA:
      return clearUserDataIds(state, action.payload.ids);
    case CONFIRM_ORDER:
      return state.simStatusResponse ? { ...state, simStatusResponse: null } : state;
    case LOCATION_CHANGE:
      return state.inbox.openDocument
        ? { ...state, inbox: { ...state.inbox, openDocument: null } }
        : { ...state, lastVisit: Date.now() };
    case DOCUMENT_READ: {
      const docs = state.inbox.documents || [];
      const { id } = action.payload;
      const documents = docs.map(d =>
        (d.id === id ? { ...d, alreadyAccessed: true } : d));
      return { ...state, inbox: { ...state.inbox, documents } };
    }
    case STORE_PASSWORD:
    case REGISTER_ORDER_BY_AGENT:
    case REGISTER_VOID:
    case REGISTER_CID:
    case REGISTER_AFFILIATE_ID:
    case RECEIVE_AFFILIATE_PARTNER_LINK:
    case RECEIVE_PPLUS_DATA:
    case UPDATE_CREDENTIALS:
      return { ...state, ...action.payload };
    case REGISTER_PROMOTION_CODES: {
      const { promoCodes } = action.payload;
      return { ...state, promoCodes };
    }
    case REMOVE_PROMOTION_CODES:
      return { ...state, promoCodes: defaultState().promoCodes };
    case REGISTER_CAMPAIGN_TOKEN: {
      const { campaignToken } = action.payload;
      return { ...state, campaignToken };
    }
    case REMOVE_CAMPAIGN_TOKEN: {
      return { ...state, campaignToken: defaultState().campaignToken };
    }
    case REGISTER_DENIED_PROMOTIONS: {
      const { promoCodes } = action.payload;
      return { ...state, deniedPromotions: { promoCodes } };
    }
    case REMOVE_DENIED_PROMOTIONS:
      return { ...state, deniedPromotions: defaultState().deniedPromotions };
    case REGISTER_GRANTED_PROMOTIONS: {
      return { ...state, ...action.payload };
    }
    case REGISTER_GRANTED_CAMPAIGN_PROMOTIONS: {
      return { ...state, ...action.payload };
    }
    case UPDATE_CART_SIM_TYPE: {
      return { ...state, ...action.payload };
    }
    // update optional booked promotions
    case UPDATE_SELECTED_OPTIONAL_PROMOTIONS: {
      const { promotions } = action.payload;
      return {
        ...state,
        promotions,
        // the updated selected promotions is not correctly recognized by areStatesEqual Check.
        // I have no idea why. Since this leads to missing re-rending when updating this checkbox
        // we use a dummy change to as workaround.
        // DBNHAMOP-5394
        selectedOptionalPromotionsChangeCounter:
          (state.selectedOptionalPromotionsChangeCounter || 0) + 1,
      };
    }
    case UPDATE_CONTRACT_DATA_ESIM_STATUS: {
      const { esimStatus } = action.payload;
      const contractDataSimCard = state.contractData.simCard;
      // eslint-disable-next-line no-prototype-builtins
      if (contractDataSimCard.hasOwnProperty('esimStatus')) {
        contractDataSimCard.esimStatus = esimStatus;
      }
      return { ...state, ...state.contractData };
    }
    case SHOW_ESIM_STATUS_REFRESH: {
      return { ...state, eSim: { ...state.eSim, ...action.payload } };
    }
    case SYNC_STORAGE: {
      const defaultsState = defaultState();
      const credentials = state.credentials.msisdn ? state.credentials : defaultsState.credentials;
      return {
        ...state,
        lastVisit: defaultsState.lastVisit,
        credentials,
        name: credentials.name,
        market: credentials.market,
        phoneNumber: credentials.phoneNumber,
        brandName: credentials.brandName,
        inbox: {
          ...state.inbox,
          documents: state.inbox.documents || defaultsState.inbox.documents,
        },
        promoCodes: state.promoCodes || defaultsState.promoCodes,
        campaignToken: state.campaignToken || defaultsState.campaignToken,
        cartSimType: state.cartSimType || defaultsState.cartSimType,
      };
    }
    case REQUEST_RECEIVED_RESPONSE:
      return integrateResponseData(state, action);
    case REQUEST_RECEIVED_ERROR:
      return integrateErrorResponseData(state, action);
    case SET_SCOPE:
      return { ...state, scope: action.payload };
    default:
      return state;
  }
}

export default user;
