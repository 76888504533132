import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  registerFootnoteReference,
  unregisterFootnoteReference,
} from '../../actions/legal/footnote';
import { stripHTML } from '../../helpers/str';
import { getLegalTarget, getVisibleLegals } from '../../helpers/legal';
import { showDialog } from '../../actions/page/dialog';

/**
 * Returns the symbol that visually identifies the footnote.
 *
 * If a footnote reference with the specified id exists and a legal
 * text is present, the reference symbol will be an incremented number.
 * Otherwise, null is returned.
 *
 * @param {array} footnotes - list of footnotes visible on the page
 * @param {object} refLegal - the referenced footnote
 * @param id - an id, e.g. an entity id
 * @param {boolean} hasLegalText
 * @param {string} defaultSymbol
 * @returns {string}
 */
const getRefSymbol = (footnotes = [], refLegal, id, hasLegalText, defaultSymbol) => {
  const footnoteIndex = footnotes.findIndex(
    (footnote) =>
      !!getLegalTarget(footnote) && getLegalTarget(footnote) === id && footnote === refLegal,
  );

  if (!hasLegalText || footnoteIndex === -1) {
    return defaultSymbol;
  }

  return String(footnoteIndex + 1);
};

/**
 * A footnote symbol is a symbol that represents and
 * identifies a footnote, e.g. an asterisk (*) or an index (1).
 *
 * The decision, which symbol to display is based on
 * the specified `id` prop.
 *
 * An id may be e.g. an entity's eid.
 *
 * For a usage example see the example provided in {@link FootnoteReference}.
 */
const FootnoteSymbol = (props) => {
  const {
    dispatch, refSymbol, legalHeadline, legalText, ui,
  } = props;

  const showDetails = (e) => {
    e.preventDefault();
    e.stopPropagation();

    dispatch(
      showDialog({
        headline: legalHeadline,
        copy: legalText,
        actions: [
          {
            label: ui.guiWordAllright,
            withoutArrow: true,
            asLink: false,
          },
        ],
      }),
    );
  };

  return (
    <button
      type="button"
      aria-label={`${ui.guiWordLegalNotesFor} ${legalHeadline}`}
      onClick={showDetails}
      aria-haspopup="dialog"
      tabIndex={0}
    >
      <sup aria-hidden>
        (
        {refSymbol}
        )
      </sup>
    </button>
  );
};

FootnoteSymbol.propTypes = {
  dispatch: PropTypes.func.isRequired,
  /**
   * The id used to identify the footnote reference in the store.
   */
  id: PropTypes.string,
  /**
   * The symbol that visually identifies the footnote. This is
   * either an incremented number or a default symbol.
   */
  refSymbol: PropTypes.string.isRequired,
  /**
   * The legal headline
   */
  legalHeadline: PropTypes.string,
  /**
   * The legal text
   */
  legalText: PropTypes.string,
  /**
   * The ui object
   */
  ui: PropTypes.object,
};

const findLegalObject = (legals, refLegal) => {
  if (!legals) {
    // not been fetched yet.
    return null;
  }

  return legals.find((l) => l === refLegal);
};

/**
 * Returns a legal data (headline and text) for the footnote id.
 */
const getLegalData = (legals, refLegal) => {
  const data = {
    legalHeadline: '',
    legalText: '',
  };

  const legal = findLegalObject(legals, refLegal);

  if (legal) {
    data.legalHeadline = legal.headline;
    data.legalText = stripHTML(legal.copyWeb);
  }

  return data;
};

const mapStateToProps = (state, ownProps) => {
  const { ui, legal } = state;
  const { id, refLegal } = ownProps;
  const { legalHeadline, legalText } = getLegalData(legal.legals, refLegal);
  const visibleLegals = getVisibleLegals(state);
  const hasLegalText = Boolean(legalText);
  const refSymbol = getRefSymbol(visibleLegals, refLegal, id, hasLegalText, ui.guiSymbolStar);

  return {
    ui,
    refSymbol,
    legalText,
    legalHeadline,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    registerFootnoteReference,
    unregisterFootnoteReference,
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(FootnoteSymbol);
