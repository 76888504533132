import React, { memo, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import ShoppingCartComposition from '../../components/compositions/cart/ShoppingCart';
import enrichCartEntities from '../preloader/PreloaderCart';

import {
  changeOptions,
  clearCart,
  clearCartSimType,
  removeItem,
  updateCartSimType,
} from '../../actions/order/cart';
import {
  showCartEmptyDialog,
  showPromoCodeDialog,
  showTariffOptionTeaserDialog,
} from '../../actions/dialog/misc';

import { shape as tariffShape } from '../../propTypes/tariff';
import { list as optionShapeList } from '../../propTypes/tariffOption';
import { shape as hardwareShape } from '../../propTypes/hardware';
import { shape as hardwareGroupShape } from '../../propTypes/hardwareGroup';

import {
  E_SIM, MARKET_POSTPAID, MARKET_PREPAID,
  MARKETING_LOCATION_CHECKOUT_CART,
  MARKETING_LOCATION_CHECKOUT_CART_LAYER,
  SIM_CARD,
} from '../../helpers/constants';

import {
  getShippingFee,
  idToTariffOptionEntity,
  isHardwareEntity,
  isTariffEntity,
  isTariffOptionEntity,
  sortByMarketingLocation,
} from '../../helpers/entity';

import { createNormalizeTargetEntitiesSelector } from '../../selectors/entity';
import {
  createMatchingOptionalPromosSelector,
  createMatchingPromosSelector,
  createMatchingSelectedOptionalPromosSelector,
} from '../../selectors/promotions';
import {
  updateSelectedOptionalPromotions,
} from '../../actions/user/promotions';
import {
  getPromotionIncentives,
  getRequiredPromotions,
} from '../../helpers/promotions';
import { areObjectsEqual } from '../../helpers/objectEquals';

class ShoppingCart extends PureComponent {
  componentDidMount() {
    const {
      theme,
      skipPromote,
      selectedTariff,
      isContractRenewal,
      isCheckoutSummary,
      onSimTypeOptionChange,
      dispatch,
    } = this.props;

    if (theme === 'full' && !skipPromote) {
      this.promoteTariffOption();
    }

    const isPua = selectedTariff && selectedTariff.market === MARKET_PREPAID;

    /** cartSimType should only be set when presales postpaid */
    if (isPua || isContractRenewal) {
      onSimTypeOptionChange(null);
      dispatch(clearCartSimType());
    }

    if ((selectedTariff && selectedTariff.market === MARKET_POSTPAID)
      && !isContractRenewal && !isCheckoutSummary) {
      this.setCartSimType();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      isCheckoutSummary,
    } = this.props;

    if (!isCheckoutSummary) {
      this.updateCartSimType(prevProps);
    }
  }

  setCartSimType() {
    const {
      onSimTypeOptionChange,
      cartSimType,
      selectedHardware,
      selectedTariff,
    } = this.props;

    /**
     * set simType value when hardware is selected
     * checks if cartSimType has a stored value
     * if not uses selectedSimType value
     */
    if (selectedHardware) {
      const selectedSimType = selectedHardware.supportsEsim ? E_SIM : SIM_CARD;
      const simType = cartSimType || selectedSimType;
      onSimTypeOptionChange(simType);
    }
    /** tariff only */
    if (selectedTariff && !selectedHardware) {
      onSimTypeOptionChange(cartSimType || SIM_CARD);
    }
  }

  updateCartSimType(prevProps) {
    const {
      selectedHardware,
      onSimTypeOptionChange,
      selectedTariff,
      isContractRenewal,
      dispatch,
    } = this.props;

    const isPua = selectedTariff && selectedTariff.market === MARKET_PREPAID;

    /** change of hardware updates simType value */
    if (((selectedHardware && prevProps.selectedHardware
        && selectedHardware.eid !== prevProps.selectedHardware.eid)
      || (selectedHardware && !prevProps.selectedHardware))) {
      const selectedSimType = selectedHardware.supportsEsim ? E_SIM : SIM_CARD;
      if (isPua || isContractRenewal) {
        onSimTypeOptionChange(null);
        dispatch(clearCartSimType());
      } else {
        onSimTypeOptionChange(selectedSimType);
      }
    }
    /** change of tariff updates simType value to default simCard  */
    if (selectedTariff && !selectedHardware && prevProps.selectedTariff
      && selectedTariff.eid !== prevProps.selectedTariff.eid) {
      if (isPua || isContractRenewal) {
        onSimTypeOptionChange(null);
        dispatch(clearCartSimType());
      } else {
        onSimTypeOptionChange(SIM_CARD);
      }
    }
  }

  promoteTariffOption() {
    const { tariffOptions, ui, onOptionsChange, selectedOptions } = this.props;
    const promotedTariffOptions = tariffOptions
      .filter(entry => entry && entry.marketingLocation &&
        entry.marketingLocation.includes(MARKETING_LOCATION_CHECKOUT_CART_LAYER));
    if (promotedTariffOptions.length && !selectedOptions.includes(promotedTariffOptions[0].eid)) {
      this.props.showTariffOptionTeaserDialog({
        headline: ui.txtPromotedTariffOptionLayerHeadline,
        copy: ui.txtPromotedTariffOptionLayerCopy,
        image: promotedTariffOptions[0].image,
        continueText: ui.guiPromotedTariffOptionLayerButton,
        cancelText: ui.guiWordNoThanks,
        onClose: () => {},
        onContinue: () => { onOptionsChange([promotedTariffOptions[0].eid]); },
      });
    }
  }

  render() {
    const {
      theme,
      selectedHardwareGroup,
      selectedHardware,
      selectedTariff,
      optionsList,
      selectedOptions,
      onOptionsChange,
      onHardwareRemove,
      onTariffRemove,
      onShowPromoCodeDialog,
      isConfirmed,
      isContractRenewal,
      withPromoCodeForm,
      dispatch,
      promoIncentives,
      promotions,
      optionalPromotions,
      selectedOptionalPromotionIds,
      selectedOptionalPromotions,
      selectedOptionalPromoIncentives,
      onOptionalPromoChange,
      isInitiallyOpen,
      isMobileCheckout,
      shippingFee,
      onSimTypeOptionChange,
      cartSimType,
    } = this.props;

    return (
      <ShoppingCartComposition
        theme={theme}
        selectedHardwareGroup={selectedHardwareGroup}
        selectedHardware={selectedHardware}
        selectedTariff={selectedTariff}
        selectedOptions={selectedOptions}
        optionsList={optionsList}
        promoIncentives={promoIncentives}
        promotions={promotions}
        optionalPromotions={optionalPromotions}
        selectedOptionalPromoIncentives={selectedOptionalPromoIncentives}
        selectedOptionalPromotions={selectedOptionalPromotions}
        selectedOptionalPromotionIds={selectedOptionalPromotionIds}
        onOptionsChange={onOptionsChange}
        onOptionalPromoChange={onOptionalPromoChange}
        onHardwareRemove={onHardwareRemove}
        onTariffRemove={onTariffRemove}
        onShowPromoCodeDialog={onShowPromoCodeDialog}
        isConfirmed={isConfirmed}
        isContractRenewal={isContractRenewal}
        withPromoCodeForm={withPromoCodeForm}
        dispatch={dispatch}
        isInitiallyOpen={isInitiallyOpen}
        isMobileCheckout={isMobileCheckout}
        shippingFee={shippingFee}
        onSimTypeOptionChange={onSimTypeOptionChange}
        cartSimType={cartSimType}
      />
    );
  }
}

ShoppingCart.propTypes = {
  theme: PropTypes.oneOf(['full', 'compact', 'widget']),
  selectedHardwareGroup: hardwareGroupShape,
  selectedHardware: hardwareShape,
  selectedTariff: tariffShape,
  optionsList: optionShapeList,
  tariffOptions: optionShapeList,
  selectedOptions: PropTypes.array.isRequired,
  onOptionsChange: PropTypes.func.isRequired,
  onOptionalPromoChange: PropTypes.func.isRequired,
  onHardwareRemove: PropTypes.func.isRequired,
  onShowPromoCodeDialog: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  promotions: PropTypes.array,
  promoIncentives: PropTypes.array,
  optionalPromotions: PropTypes.array,
  selectedOptionalPromotions: PropTypes.array,
  selectedOptionalPromotionIds: PropTypes.array,
  selectedOptionalPromoIncentives: PropTypes.array,
  promotionIncludedProducts: PropTypes.array,
  isContractRenewal: PropTypes.bool,
  isConfirmed: PropTypes.bool,
  withPromoCodeForm: PropTypes.bool,
  showTariffOptionTeaserDialog: PropTypes.func,
  ui: PropTypes.object.isRequired,
  skipPromote: PropTypes.bool,
  isInitiallyOpen: PropTypes.bool,
  shippingFee: PropTypes.object,
  onSimTypeOptionChange: PropTypes.func,
  cartSimType: PropTypes.string,
  isCheckoutSummary: PropTypes.bool,
};

ShoppingCart.defaultProps = {
  theme: 'full',
  optionsList: [],
  tariffOptions: [],
};

const isSelectableOption = selectedOptions => (option) => {
  const isPresales = option.presales;
  const isMarketingLocation = !!option.marketingLocation
    || option.marketingLocation.includes(MARKETING_LOCATION_CHECKOUT_CART);
  const isBookableWithAllSelected = selectedOptions.every(
    selectedOption => !option.notBookableWith.includes(selectedOption.eid),
  );

  return isPresales && isMarketingLocation && isBookableWithAllSelected;
};

function makeMapStateToProps() {
  const normalizeTargetEntitiesSelector = createNormalizeTargetEntitiesSelector({ style: 'strike' });
  const normalizeOptionEntitiesSelector = createNormalizeTargetEntitiesSelector({ style: 'strike' });
  const matchingPromotionsSelector = createMatchingPromosSelector({ style: 'none' });
  const matchingOptionalPromotionsSelector = createMatchingOptionalPromosSelector({ style: 'none' });
  const matchingSelectedOptionalPromotionsSelector = createMatchingSelectedOptionalPromosSelector({ style: 'none' });
  return (state, ownProps) => {
    const {
      cartHardwareGroup,
      cartHardware,
      cartTariff,
      cartTariffOptions,
      cartSelectedOptions,
    } = ownProps;
    const { site, ui, user } = state;
    const selectedOptionIds = cartSelectedOptions.map(option => option.eid);
    const isContractRenewal = site.contractRenewal && site.contractRenewal.isInProgress;
    const targets = [cartTariff, cartHardware, ...cartSelectedOptions];
    // all promotions
    const promotions = matchingPromotionsSelector(state, targets);
    // all required promotions without optional selected promotions
    const requiredPromotions = getRequiredPromotions(promotions);
    // promotions with customer action needed and not yet activated by customer
    const optionalPromotions = matchingOptionalPromotionsSelector(state, targets);
    // promotions with customer action needed and already activated by customer
    const selectedOptionalPromotions = matchingSelectedOptionalPromotionsSelector(state, targets);
    // promo incentives without customer action needed promotions
    const promoIncentives = getPromotionIncentives(requiredPromotions);
    // promo incentives without customer action needed
    const selectedOptionalPromoIncentives = getPromotionIncentives(selectedOptionalPromotions);
    // products that are binded by promotion
    const entities = (cartTariff || cartHardware)
      ? normalizeTargetEntitiesSelector(state, targets)
      : [];
    const optionsList = cartTariffOptions &&
      normalizeOptionEntitiesSelector(state, cartTariffOptions)
        .filter(isSelectableOption(cartSelectedOptions));
    const filteredTariffOptionEntities = optionsList
      .filter(option => !cartSelectedOptions
        .find(selectedOption => selectedOption.eid === option.eid));
    const selectedTariffEntity = entities.find(isTariffEntity);
    const selectedHardwareEntity = entities.find(isHardwareEntity);
    const selectedTariffOptionEntity = entities.filter(isTariffOptionEntity);
    const shippingFee = getShippingFee(
      selectedHardwareEntity,
      selectedTariffEntity,
      state.entities.tariffVO,
      isContractRenewal,
    );
    return {
      selectedHardwareGroup: cartHardwareGroup,
      selectedHardware: selectedHardwareEntity,
      selectedTariff: selectedTariffEntity,
      entities,
      tariffOptions: [...filteredTariffOptionEntities, ...selectedTariffOptionEntity]
        .sort(sortByMarketingLocation),
      optionsList,
      selectedOptions: selectedOptionIds,
      promotions,
      promoIncentives,
      optionalPromotions,
      selectedOptionalPromotions,
      selectedOptionalPromoIncentives,
      selectedOptionalPromotionIds: state.user.selectedOptionalPromotions,
      isContractRenewal,
      ui,
      shippingFee,
      cartSimType: user.cartSimType,
    };
  };
}

const mapDispatchToProps = dispatch => ({
  onOptionsChange: ids => dispatch(changeOptions(ids.map(idToTariffOptionEntity))),
  onSimTypeOptionChange: (simType) => {
    dispatch(updateCartSimType(simType));
  },
  onOptionalPromoChange: ids => dispatch(updateSelectedOptionalPromotions(ids)),
  onHardwareRemove: id => dispatch(removeItem(id)),
  onShowPromoCodeDialog: (ev) => {
    ev.preventDefault();
    dispatch(showPromoCodeDialog());
  },
  dispatch,
  showTariffOptionTeaserDialog: params => dispatch(showTariffOptionTeaserDialog(params)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onTariffRemove: (id) => {
    const { dispatch } = dispatchProps;
    const { cartHardware } = ownProps;
    if (cartHardware) {
      dispatch(showCartEmptyDialog(id));
    } else {
      dispatch(removeItem(id));
      dispatch(clearCart());
    }
  },
});

export default compose(
  enrichCartEntities(),
  connect(
    makeMapStateToProps,
    mapDispatchToProps,
    mergeProps,
    {
      areStatesEqual: (next, prev) => areObjectsEqual(next, prev, {
        site: { contractRenewal: {}, bookablePromotions: {}, optionalPromotions: {} },
        ui: {},
        user: {
          selectedOptionalPromotions: {},
          cartSimType: {},
          selectedOptionalPromotionsChangeCounter: {}, // workaround DBNHAMOP-5394
          promoCodes: 'json',
        },
        entities: 'json',
      }, false),
      areOwnPropsEqual: (next, prev) => areObjectsEqual(next, prev, {
        cartHardwareGroup: {},
        cartHardware: {},
        cartTariff: {},
        cartTariffOptions: 'json',
        cartSelectedOptions: 'json',
      }, false),
    },
  ),
  memo,
)(ShoppingCart);
